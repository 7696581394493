import axios, { AxiosInstance } from "axios";
import { getApiConfig } from "../config";
import { SurveyPartData } from "../types/surveyTypes";
import { ApiResponse } from "../types/apiTypes";
import Analytics from "../utils/analytics";

const apiUrl = `${getApiConfig().baseUrl}/onboarding`;

export const getSurveyData = async (axiosInstance: AxiosInstance = axios) => {
  const response = await axiosInstance.get<ApiResponse<SurveyPartData[]>>(
    apiUrl
  );

  if (!response.data.success) {
    console.error(`Failed GET ${apiUrl}`);
    return [] as SurveyPartData[];
  }

  return response?.data.data as SurveyPartData[];
};

export const saveSurveyPart = async (
  allParts: SurveyPartData[],
  partToSave: SurveyPartData,
  axiosInstance: AxiosInstance = axios
) => {
  const transformedData = {
    answers: partToSave.questions.map((q) => ({
      question_id: q.id,
      answers_ids: q.answers.filter((x) => x.is_user_answer).map((x) => x.id),
    })),
  };

  const response = await axiosInstance.post(
    `${apiUrl}/answers`,
    transformedData
  );

  if (!response.data.success) {
    console.error(`Failed POST ${apiUrl}/answers`);
    return;
  }

  // Send analytics event
  Analytics.trackEvent({
    category: "Survey",
    action: "survey_part_submit",
    label: `Send ${partToSave.text} part`, // Part name
    value: `part_${partToSave.order + 1}_of_${allParts.length}`,
  });

  return response.data;
};
