import { AsyncReducer } from "../../hooks/useAsyncReducer";
import { CREATE_USER, LOAD_USER, UserContextAction, UserContextActionData, UserContextState } from "./types";
import { UserData } from "../../types/userTypes";
import { getUser, postUser } from "../../api/userApi";

export const userReducer: AsyncReducer<
  UserData | undefined,
  UserContextState,
  UserContextAction,
  UserContextActionData
> = async (state, setState, action, axiosInstance) => {
  switch (action.type) {
    case LOAD_USER:
      const user = await getUser(axiosInstance);
      if (user) {
        setState((state) => {
          state.data = user;
        });
      }

      return;
    case CREATE_USER:
      await postUser(action.data as UserData, axiosInstance);
      setState((state) => {
        state.data = action.data as UserData;
      });

      return;
  }
}