import { Button, Stack, Title, Text } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const PricingBanner: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack align="center">
      <Title order={2} weight="bold" align="center">
        Don’t miss out on our amazing features!
      </Title>
      <Text align="center" color="dimmed">
        Upgrade your account today and enjoy access to our services.
      </Text>
      <Button size="lg" onClick={() => navigate("/pricing")}>
        See pricing plans
      </Button>
    </Stack>
  );
};

export default PricingBanner;
