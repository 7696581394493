import React, { useState, useEffect } from 'react';
import { AnswerData } from '../../../types/surveyTypes';
import { Badge, Box, Center, CloseButton, Flex, MultiSelect, MultiSelectValueProps, SelectItemProps, rem } from '@mantine/core';
import { forwardRef } from 'react';

interface AutocompleteAnswersProps {
  answers: AnswerData[];
  onChange: (id: string) => void;
}

function Value({
  value,
  onRemove,
  classNames,
  ...others
}: MultiSelectValueProps & { value: string }) {
  const [code, ...rest] = value?.split(' ') || [];
  const name = rest.join(' ');

  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
          border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
            }`,
          borderRadius: theme.radius.lg,
        })}
      >
        <Center mr={10} >
          <Badge size='lg'>{code}</Badge>
        </Center>
        <Box sx={{ lineHeight: 1, fontSize: rem(12) }}>{name}</Box>
        <CloseButton
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

const Item = forwardRef<HTMLDivElement, SelectItemProps>(({ value, ...others }, ref) => {
  const [code, ...rest] = value?.split(' ') || [];
  const name = rest.join(' ');

  return (
    <div ref={ref} {...others}>
      <Flex align="center">
        <Center mr={10}>
          <Badge>{code}</Badge>
        </Center>
        <Box mr={10}>
          {name}
        </Box>
      </Flex>
    </div>
  );
});

const GridAutocompleteAnswers: React.FC<AutocompleteAnswersProps> = ({
  answers,
  onChange,
}) => {
  const selectedAnswers = answers.filter(x => x.is_user_answer);
  const options = answers.map((answer) => answer.text);
  const [selectedOptions, setSelectedOptions] = useState(selectedAnswers.map((answer) => answer.text));

  useEffect(() => {
    selectedOptions.forEach((option: string) => {
      const id = answers.find(answer => answer.text === option)?.id;
      if (id && !selectedAnswers.some((answer) => answer.id === id)) {
        onChange(id);
      }
    });

    selectedAnswers.forEach((answer: AnswerData) => {
      if (!selectedOptions.includes(answer.text)) {
        onChange(answer.id);
      }
    });
  }, [selectedOptions, selectedAnswers, answers, onChange]);

  const handleAutocompleteChange = (newSelectedOptions: string[]) => {
    setSelectedOptions(newSelectedOptions);
  };

  return (
    <MultiSelect
      data={options}
      onChange={handleAutocompleteChange}
      value={selectedOptions}
      valueComponent={Value}
      itemComponent={Item}
      placeholder="Type to search"
      searchable
      nothingFound="Nothing found"
    />
  );
};

export default GridAutocompleteAnswers;