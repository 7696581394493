import { useAuth0 } from "@auth0/auth0-react";
import {
  Text,
  Button,
  Header as MantineHeader,
  Group,
  Avatar,
  Burger,
  useMantineTheme,
  MediaQuery,
  Title,
  Stack,
  UnstyledButton,
  Menu,
} from "@mantine/core";

import { useStyles } from "./Header.styles";
import {
  useAppStateActionsContext,
  useAppStateContext,
} from "../../../contexts/AppContext";
import logo from "../../../assests/logo.svg";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../contexts/UserContext";

interface HeaderProps {
  showBurger?: boolean;
}

const Header: React.FC<HeaderProps> = () => {
  const { logout, user } = useAuth0();
  const userData = useUserContext();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const appStateActions = useAppStateActionsContext();
  const { isShownNavigationBar } = useAppStateContext();

  const onLogoutClicked = () => {
    logout();
  };

  return (
    <MantineHeader height={60}>
      <Group position="apart" h="100%" px={15} w="100%">
        <Group style={{ cursor: "pointer" }} onClick={() => navigate("")}>
          <img width="40px" src={logo} alt="ailergic" />
          <Title order={2}>Ailergic</Title>
        </Group>

        <Group position="right" align="center" h="100%" px={15}>
          {userData.data && (
            <Group className={classes.userInfoFullScreen}>
              <Menu trigger="hover" radius="md" width="200px">
                <Menu.Target>
                  <UnstyledButton>
                    <Group>
                      <Avatar
                        src={user?.picture}
                        alt={userData.data?.first_name}
                        size="md"
                        radius="xl"
                      />
                      <Stack spacing="0">
                        <Text>{`${userData.data?.first_name} ${userData.data?.last_name}`}</Text>
                        <Text
                          mt="-5px"
                          size="sm"
                          c="gray.6"
                          style={{ letterSpacing: "-0.5px" }}
                        >
                          {user?.email}
                        </Text>
                      </Stack>
                    </Group>
                  </UnstyledButton>
                </Menu.Target>

                <Menu.Dropdown bg="gray.2" mt="4px">
                  <Menu.Item onClick={() => navigate("/pricing")}>
                    Pricing
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate("/subscription")}
                    disabled={!userData.data?.subscription}
                  >
                    Manage subscription
                  </Menu.Item>
                  <Menu.Item onClick={onLogoutClicked}>Log out</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          )}

          {userData.data && (
            <>
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={isShownNavigationBar}
                  onClick={() =>
                    appStateActions.setIsShownNavigationBar(
                      !isShownNavigationBar
                    )
                  }
                  size="sm"
                  color={theme.colors.gray[6]}
                />
              </MediaQuery>
            </>
          )}
        </Group>
      </Group>
    </MantineHeader>
  );
};

export default Header;
