import axios, { AxiosError, AxiosInstance } from "axios";

import { getApiConfig } from "../config";

const apiUrl = `${getApiConfig().baseUrl}/subscription`;

export interface SubscriptionPlanData {
  id: number;
  name: string;
  billing_type: "month" | "year";
  billing_period?: number;
  initial_price?: {
    USD: number;
  };
  recurring_price: {
    USD: number;
  };
  trial_days: number;
}

export const getSubscriptionPlans = async (
  axiosInstance: AxiosInstance = axios
) => {
  try {
    const response = await axiosInstance.get(apiUrl + "/list");
    return response.data.data as SubscriptionPlanData[];
  } catch (err: AxiosError | any) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return [];
    }

    throw err;
  }
};

export const cancelSubscription = async (
  subscriptionId: string,
  axiosInstance: AxiosInstance = axios
) => {
  try {
    const response = await axiosInstance.get(
      `${apiUrl}/cancel?subscription_id=${subscriptionId}`
    );
    return response.data;
  } catch (err: AxiosError | any) {
    throw err;
  }
};
