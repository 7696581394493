import {
  Button,
  Card,
  Center,
  Container,
  MediaQuery,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { DynamicSourceType } from "party-js/lib/systems/sources";
import party from "party-js";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    party.confetti(containerRef.current as DynamicSourceType, {
      count: 100,
      spread: 3000,
    });
  }, []);

  return (
    <Container ref={containerRef} w="100%">
      <MediaQuery smallerThan="sm" styles={{ margin: "0" }}>
        <Card mx="100px" my="lg" mih="93%">
          <Center>
            <Stack align="center">
              <Title>Thank you!</Title>
              <Text>Your subscription has been successfully processed.</Text>
              <Text>You will receive an email confirmation shortly.</Text>
              <Text>Your existing notifications has been enabled.</Text>
              <Button mt="sm" mb="sm" onClick={() => navigate("/")}>
                Go to Home page
              </Button>
            </Stack>
          </Center>
        </Card>
      </MediaQuery>
    </Container>
  );
};

export default ThankYouPage;
