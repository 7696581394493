import { Reducer } from "react";
import {
  AppStateData,
  AppStateContextAction,
  SET_IS_SHOWN_NAVIGATION_BAR,
} from "./types";

export const appStateReducer: Reducer<AppStateData, AppStateContextAction> = (
  state: AppStateData,
  action: AppStateContextAction
) => {
  switch (action.type) {
    case SET_IS_SHOWN_NAVIGATION_BAR: {
      return {
        ...state,
        isShownNavigationBar: action.data!.isShownNavigationBar,
      };
    }
    default:
      return state;
  }
};
