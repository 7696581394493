import { AsyncDispatch } from "../../hooks/useAsyncReducer";
import { UserData } from "../../types/userTypes";
import { UserContextActionData, UserContextAction, LOAD_USER, CREATE_USER, UserContextActions } from "./types";

export const createUserActions = (
  dispatch: AsyncDispatch<UserContextActionData, UserContextAction>
) => {
  return {
    load: () => dispatch({ type: LOAD_USER }),
    create: (user: UserData) => dispatch({ type: CREATE_USER, data: user })
  } as UserContextActions;
};
