// NotificationForm.tsx
import React, { useEffect, useState } from "react";
import { useForm, isNotEmpty, isEmail, matches } from "@mantine/form";
import {
  TextInput,
  Select,
  Group,
  Button,
  Input,
  Text,
  Stack,
  Stepper,
  Collapse,
  Anchor,
  Paper,
  Card,
  Center,
  CloseButton,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { IconClock, IconMapPin } from "@tabler/icons-react";
import InputMask from "react-input-mask";
import { useDisclosure } from "@mantine/hooks";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/mobile";

import "./NotificationForm.css";
import {
  ChannelType,
  MsgFormatType,
  NotificationData,
} from "../../../types/notificationTypes";
import { useStyles } from "./NotificationForm.styles";
import {
  NotificationFormProps,
  channelIconMap,
  channelOptions,
  formatToMessage,
  msgFormatIconMap,
  msgFormatOptions,
} from "./NotificationForm.consts";
import {
  useRegionsActionsContext,
  useRegionsContext,
} from "../../../contexts/RegionsContext";

const NotificationForm: React.FC<NotificationFormProps> = ({
  notification,
  isUpdatingNotification,
  onSubmit,
  onCancel,
}) => {
  let possibleActiveStep = 0;
  const steps = 3;
  const [activeStep, setActiveStep] = useState(0);
  const { classes } = useStyles();
  const [selectedFormat, setSelectedFormat] = useState(notification.msg_format);
  const [selectedChannel, setSelectedChannel] = useState(notification.channel);
  const [isShownTimeZone, { toggle }] = useDisclosure(false);
  const {
    initialRegions,
    data: regionsData,
    timezones,
    searchText,
    countries,
  } = useRegionsContext();
  const [localInitialRegions, setLocalInitialRegions] =
    useState(initialRegions);

  const { searchByText, selectCountry } = useRegionsActionsContext();
  const showSaveButton = isUpdatingNotification && activeStep !== steps - 1;

  const form = useForm({
    initialValues: notification,
    validateInputOnChange: true,

    validate: (values: NotificationData) => {
      if (possibleActiveStep === 0) {
        return {
          region_id: isNotEmpty()(values.region_id),
          timezone: isNotEmpty()(values.timezone),
          zoned_time: isNotEmpty()(values.zoned_time),
        };
      }

      if (possibleActiveStep === 1) {
        return {
          phone_number:
            (values.channel === "sms" || values.channel === "whatsapp") &&
            !values.phone_number
              ? "Invalid phone number"
              : null,
          email_address:
            values.channel === "email" && isEmail()(values.email_address)
              ? "Invalid email"
              : null,
        };
      }

      if (possibleActiveStep === 2) {
        return { msg_format: isNotEmpty()(values.msg_format) };
      }
      return {};
    },
  });

  useEffect(() => {
    setSelectedFormat(notification.msg_format);
    setSelectedChannel(notification.channel);
    form.setValues(notification);
  }, [notification]);

  useEffect(() => {
    setLocalInitialRegions(initialRegions);
  }, [initialRegions]);

  const setStepWithValidation = (step: number) => {
    // not valid step
    if (step < 0 || step >= steps) {
      return;
    }

    // set step back
    if (step <= activeStep) {
      setActiveStep(step);
      return;
    }

    // check if each step further has valid form values
    // set first not valid
    for (let i = activeStep; i <= step; i++) {
      possibleActiveStep = i;
      if (form.validate().hasErrors) {
        setActiveStep(i);
        return;
      }
    }

    // set regular format for whatsapp
    if (
      step === steps - 1 &&
      ["whatsapp", "sms"].includes(form.values.channel)
    ) {
      onMsgFormatChange("regular");
    }

    setActiveStep(step);
  };

  const onMsgFormatChange = (value: MsgFormatType) => {
    if (
      ["whatsapp", "sms"].includes(form.values.channel) &&
      value !== "regular"
    ) {
      return;
    }

    setSelectedFormat(value);
    form.setFieldValue("msg_format", value);
  };

  const onChannelChange = (value: ChannelType) => {
    setSelectedChannel(value);
    form.setFieldValue("channel", value);
  };

  const onCityChange = (region_id: string) => {
    if (region_id === form.values.region_id) {
      searchByText(
        `${notification.region?.city_name}, ${notification.region?.state_id}` as string
      );
      return;
    }

    const region = regionsData
      .concat(initialRegions)
      .find((x) => x.id === region_id);
    notification.region = region;
    form.setFieldValue("timezone", region?.timezone_name || "");
    form.setFieldValue("region_id", region_id);
    searchByText(region?.city_name || "");
  };

  const onCountryChange = (country: string) => {
    form.setFieldValue("country", country);
    setLocalInitialRegions([]);
    selectCountry(country);
    form.setFieldValue("region_id", "");
    notification.region = undefined;
  };

  const onCustomSubmit = (values: NotificationData) => {
    if (form.values.channel === "email") {
      values.phone_number = undefined;
    } else if (
      form.values.channel === "sms" ||
      form.values.channel === "whatsapp"
    ) {
      values.email_address = undefined;
    }
    console.log(values);
    onSubmit(values);
  };

  const onSave = (values: NotificationData) => {
    for (let i = 0; i <= steps - 1; i++) {
      possibleActiveStep = i;
      if (form.validate().hasErrors) {
        setActiveStep(i);
        return;
      }
    }
    onCustomSubmit(form.values);
  };

  return (
    <form
      onSubmit={form.onSubmit((values: NotificationData) => {
        onCustomSubmit(values);
      })}
    >
      <Group align="flex-end" position="right">
        <CloseButton size="sm" onClick={onCancel} />
      </Group>
      <Stack justify="center" align="stretch" mt="xs" spacing="xs">
        <Stepper
          active={activeStep}
          classNames={{ steps: classes.steps }}
          size="xs"
          onStepClick={(i) => setStepWithValidation(i)}
        >
          <Stepper.Step description="Time & Location">
            <Center className={classes.stepTextBox}>
              <Text ta="center">
                Pick the country, city & time for your notification
              </Text>
            </Center>
            <Group position="center" w="100%">
              <Select
                required
                mt="xs"
                className={classes.inputField}
                icon={<IconMapPin size="1rem" stroke={1.5} />}
                data={countries}
                labelProps={{ size: "xs" }}
                placeholder="Select country"
                initiallyOpened={false}
                mb="sm"
                w="100%"
                {...form.getInputProps("country")}
                onChange={onCountryChange}
              />
            </Group>

            <Group position="center" w="100%">
              <Select
                required
                className={classes.inputField}
                icon={<IconMapPin size="1rem" stroke={1.5} />}
                searchable
                data={(regionsData.length > 0
                  ? regionsData
                  : notification.region
                  ? [notification.region]
                  : localInitialRegions
                ).map((x) => {
                  const label =
                    x.state_id !== undefined && x.state_id !== null
                      ? `${x.city_name}, ${x.state_id}`
                      : x.city_name;
                  return {
                    value: x.id,
                    label: label,
                  };
                })}
                maxDropdownHeight={100}
                labelProps={{ size: "xs" }}
                placeholder="Type and select city"
                initiallyOpened={false}
                nothingFound="No options"
                onSearchChange={searchByText}
                searchValue={searchText}
                mb="sm"
                w="100%"
                {...form.getInputProps("region_id")}
                disabled={!form.getInputProps("country").value}
                onChange={onCityChange}
              />
            </Group>

            <Group position="center">
              <TimeInput
                className={classes.inputField}
                required
                icon={<IconClock size="1rem" stroke={1.5} />}
                labelProps={{ size: "xs" }}
                placeholder="Time"
                w="100%"
                {...form.getInputProps("zoned_time")}
              />
            </Group>

            <Collapse
              in={!isShownTimeZone}
              transitionDuration={175}
              transitionTimingFunction={
                !isShownTimeZone ? "step-end" : "step-start"
              }
              animateOpacity={false}
            >
              <Group position="left">
                <Anchor onClick={toggle} className={classes.optionsAnchor}>
                  More options
                </Anchor>
              </Group>
            </Collapse>

            <Collapse in={isShownTimeZone} transitionDuration={350}>
              <Group position="center" mt="sm">
                <Select
                  className={classes.inputField}
                  required
                  searchable
                  w="100%"
                  data={timezones.map((x) => {
                    return {
                      value: x.timezone_name,
                      label: x.timezone_display_name,
                    };
                  })}
                  labelProps={{ size: "xs" }}
                  placeholder="Select Time Zone"
                  nothingFound="No options"
                  {...form.getInputProps("timezone")}
                  disabled={!form.getInputProps("country").value}
                />
              </Group>
              <Group position="left">
                <Anchor onClick={toggle} className={classes.optionsAnchor}>
                  Less options
                </Anchor>
              </Group>
            </Collapse>
          </Stepper.Step>

          <Stepper.Step description="Channel">
            <Center className={classes.stepTextBox}>
              <Text ta="center">
                Choose where you prefer to receive the notification
              </Text>
            </Center>
            <Group spacing="xs" position="apart" mt="xs" mb="md">
              {channelOptions.map((option) => {
                const Icon = channelIconMap[option.value];
                const isSelected = selectedChannel.includes(option.value);
                return (
                  <Card
                    className={isSelected ? classes.cardSelected : classes.card}
                    withBorder
                    onClick={() => onChannelChange(option.value)}
                    key={option.value}
                  >
                    <Center className={classes.cardCenter}>
                      {Icon}
                      <Text
                        className={
                          isSelected ? classes.textSelected : classes.text
                        }
                        ml="xs"
                        size="sm"
                      >
                        {option.label}
                      </Text>
                    </Center>
                  </Card>
                );
              })}
            </Group>

            {(form.values.channel === "sms" ||
              form.values.channel === "whatsapp") && (
              <Group position="center" style={{ gap: "calc(0.3125rem)" }}>
                <PhoneInput
                  className={
                    "phone-input " +
                    (form.getInputProps("phone_number").error ? "error" : "")
                  }
                  placeholder="+1 (702) 123-4567"
                  value={form.values.phone_number || ""}
                  required
                  onChange={(value) => {
                    console.log(value);
                    form.setFieldValue("phone_number", value);
                  }}
                />
                {form.getInputProps("phone_number").error && (
                  <span className="error-message">
                    {form.getInputProps("phone_number").error}
                  </span>
                )}
              </Group>
            )}
            {form.values.channel === "email" && (
              <Group position="center">
                <TextInput
                  w="100%"
                  className={classes.inputField}
                  required
                  placeholder="Email Address"
                  {...form.getInputProps("email_address")}
                />
              </Group>
            )}
          </Stepper.Step>

          <Stepper.Step description="Format">
            <Center className={classes.stepTextBox}>
              <Stack>
                <Text ta="center">
                  How do you want it to look? Preview each type below!
                </Text>
                {form.values.channel === "whatsapp" && (
                  <Text mt="-sm" size="sm" ta="center" color="dimmed" italic>
                    *for WhatsApp only Regular format is available*
                  </Text>
                )}
                {form.values.channel === "sms" && (
                  <Text mt="-sm" size="sm" ta="center" color="dimmed" italic>
                    *for SMS only Regular format is available*
                  </Text>
                )}
              </Stack>
            </Center>
            <Group position="apart" mt="xs" mb="sm">
              {msgFormatOptions.map((option) => {
                const Icon = msgFormatIconMap[option.value];
                const isSelected = selectedFormat.includes(option.value);

                return (
                  <Card
                    className={isSelected ? classes.cardSelected : classes.card}
                    withBorder
                    onClick={() => onMsgFormatChange(option.value)}
                    key={option.value}
                  >
                    <Center className={classes.cardCenter}>
                      {Icon}
                      <Text
                        className={
                          isSelected ? classes.textSelected : classes.text
                        }
                        ml="xs"
                        size="sm"
                      >
                        {option.label}
                      </Text>
                    </Center>
                  </Card>
                );
              })}
            </Group>

            <Group position="center">
              <Paper p="sm" w="100%" withBorder className={classes.msgPaper}>
                {formatToMessage[form.values.msg_format]}
              </Paper>
            </Group>
          </Stepper.Step>
        </Stepper>

        <Group position={showSaveButton ? "apart" : "right"} mt="sm">
          {/* show save button on each step except the last if form is valid */}
          {showSaveButton && (
            <Button onClick={() => onSave(form.values)}>Save</Button>
          )}
          <Group position="right" spacing="md" ml="xs">
            {activeStep !== 0 && (
              <Button
                variant="default"
                onClick={() => setStepWithValidation(activeStep - 1)}
              >
                Back
              </Button>
            )}

            {activeStep !== steps - 1 && (
              <Button onClick={() => setStepWithValidation(activeStep + 1)}>
                Next step
              </Button>
            )}

            {activeStep === steps - 1 && (
              <Button type="submit">
                {isUpdatingNotification ? "Save" : "Add"}
              </Button>
            )}
          </Group>
        </Group>
      </Stack>
    </form>
  );
};

export default NotificationForm;
