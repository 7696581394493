import { Updater, useImmer } from "use-immer";
import { AxiosInstance } from "axios";
import { Immutable } from "immer";

import { BaseContext, BaseContextAction } from "../types/contextTypes";
import { useApiClient } from "../contexts/AxiosContext";

export type AsyncReducer<TData, TState extends BaseContext<TData>, TAction extends BaseContextAction<TActionData>, TActionData = TData> = (
  state: Immutable<TState>,
  setState: Updater<TState>,
  action: TAction,
  axiosInstance: AxiosInstance
) => Promise<void>;

export type AsyncDispatch<TData, TAction extends BaseContextAction<TData>> = (action: TAction) => Promise<void>;

function useAsyncReducer<TData, TState extends BaseContext<TData>, TAction extends BaseContextAction<TActionData>, TActionData = TData>(
  reducer: AsyncReducer<TData, TState, TAction, TActionData>,
  initialState: TState
  //axiosInstance: AxiosInstance = axios
) : [ TState, (action: TAction) => Promise<void> ] {
  const [state, setState] = useImmer(initialState);
  const apiClient = useApiClient();

  const dispatch: AsyncDispatch<TActionData, TAction> = async (action) => {
    setState(state => { state.isLoading = true });

    try {
      await reducer(state as Immutable<TState>, setState, action, apiClient);

      setState(state => { 
        state.isLoading = false 
      });
    } catch (err) {
      console.error({err});
      setState(state => {
        state.isLoading = false;
        state.isError = true;
        state.error = err as Error;
      });
    }
  };

  return [state, dispatch];
}

export default useAsyncReducer;
