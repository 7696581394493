import { AsyncDispatch } from "../../hooks/useAsyncReducer";
import {
  CHANGE_ANSWER,
  LOAD,
  SAVE_PART,
  SurveyContextAction,
  SurveyContextActionData,
  SurveyContextActions,
} from "./types";

export const createSurveyActions = (
  dispatch: AsyncDispatch<SurveyContextActionData, SurveyContextAction>
) => {
  return {
    load: () => dispatch({ type: LOAD }),
    savePart: (partId: string) => dispatch({ type: SAVE_PART, data: { partId } }),
    changeAnswer: (partId: string, questionId: string, answerId: string) =>
      dispatch({ type: CHANGE_ANSWER, data: { partId, questionId, answerId } }),
  } as SurveyContextActions;
};
