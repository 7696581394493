import { Card, Group, MediaQuery } from "@mantine/core";
import Survey from "./Survey";

function SurveyPage() {
  return ( 
    <MediaQuery smallerThan="sm" styles={{margin: "0", padding: "0.5rem"}}>
      <Card mx="100px" my="lg" mih="93%">
        <Group>
          <Survey />
        </Group>
      </Card>
    </MediaQuery>
  );
}

export default SurveyPage;
