import { Button, Checkbox, TextInput, Text, Group, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { UserData } from "../../types/userTypes";
import { getLegalAgreementsConfig } from "../../config";

interface RegistrationFormProps {
  initialValues: UserData;
  onSubmit: (values: UserData) => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const form = useForm({
    initialValues: initialValues,
    validate: {
      terms_accepted: (value) =>
        value ? null : "Please accept terms and conditions",
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} style={{ height: "100%" }}>
      <Stack justify="center" align="center" mt="xl">
        <Group position="apart" w="310px">
          <Text>First name</Text>
          <TextInput
            placeholder="First name"
            {...form.getInputProps("first_name")}
          />
        </Group>
        <Group position="apart" mt="sm" w="310px">
          <Text>Last name</Text>
          <TextInput
            placeholder="Last name"
            {...form.getInputProps("last_name")}
          />
        </Group>
        <Group position="left" w="310px">
          <Checkbox
            mt="sm"
            radius="xs"
            label={
              <>
                I have read and agree to the&nbsp;
                <a href={getLegalAgreementsConfig().termsAndConditions} target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>
                &nbsp;and&nbsp;
                <a href={getLegalAgreementsConfig().privacyPolicy} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </>
            }
            {...form.getInputProps("terms_accepted", { type: "checkbox" })}
          />
        </Group>
        <Button type="submit" mt="sm">
          Continue
        </Button>
      </Stack>
    </form>
  );
};

export default RegistrationForm;
