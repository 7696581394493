import axios from "axios";

import { SurveyPartData } from "../../types/surveyTypes";
import {
  SurveyContextState,
  SurveyContextAction,
  LOAD,
  SAVE_PART,
  CHANGE_ANSWER,
  SurveyContextActionData,
} from "./types";
import { Draft } from "immer";

import { AsyncReducer } from "../../hooks/useAsyncReducer";
import { getSurveyData, saveSurveyPart } from "../../api/surveyApi";


const getLastCompletedPartIndex = (data: SurveyPartData[]) =>
  data.findLastIndex((part) =>
    part.questions.every((q) => q.answers.some((a) => a.is_user_answer))
  );

export const surveyReducer: AsyncReducer<
  SurveyPartData[],
  SurveyContextState,
  SurveyContextAction,
  SurveyContextActionData
> = async (state, setState, action, axiosInstance = axios) => {
  switch (action.type) {
    case LOAD:
      const surveyData = await getSurveyData(axiosInstance);
      const lastCompletedPart = getLastCompletedPartIndex(surveyData);
      const progressPercentage = (lastCompletedPart + 1) / surveyData.length * 100;

      // temporary formating part text
      surveyData.forEach(part => {
        part.text = part.text.charAt(0).toUpperCase() + part.text.slice(1);
        part.text = part.text.replace('_', ' ');
      });

      setState((draft) => {
        draft.data = surveyData;
        draft.currentPartIndex = lastCompletedPart + 1;
        draft.progressPercentage = progressPercentage;
        draft.isCompleted = progressPercentage === 100;
      });
      return;
    case SAVE_PART:
      const partData = state.data.find((x) => x.id === action.data?.partId);
      if (partData) {
        await saveSurveyPart(state.data as SurveyPartData[], partData as SurveyPartData, axiosInstance);       

        setState((draft: Draft<SurveyContextState>) => {
          if(!draft.data) return
          const lastCompletedPart = getLastCompletedPartIndex(draft.data);
          const progressPercentage = (lastCompletedPart + 1) / draft.data.length * 100;

          draft.currentPartIndex = lastCompletedPart + 1;
          draft.progressPercentage = progressPercentage;
          draft.isCompleted = progressPercentage === 100;
        });
      }

      return;
    case CHANGE_ANSWER:
      setState((draft) => {
        const question = draft.data
          .filter((x) => x.id === action.data?.partId)[0]
          ?.questions.find((x) => x.id === action.data?.questionId);
        if (!question) {
          return;
        }

        const answer = question.answers.find(
          (x) => x.id === action.data?.answerId
        );
        if (!answer) {
          return;
        }

        answer.is_user_answer = !answer.is_user_answer;

        if (!question.is_multiselect && answer.is_user_answer) {
          question.answers
            .filter((x) => x.id !== action.data?.answerId)
            .forEach((x) => (x.is_user_answer = false));
        }
      });

      return;
    default:
      return;
  }
};
