import { CustomDispatch } from "../../types/contextTypes";
import {
  AppStateData,
  AppStateContextAction,
  AppStateContextActions,
  SET_IS_SHOWN_NAVIGATION_BAR,
} from "./types";

export const createAppStateActions = (
  dispatch: CustomDispatch<AppStateData, AppStateContextAction>
) => {
  return {
    setIsShownNavigationBar: (value: boolean) =>
      dispatch({
        type: SET_IS_SHOWN_NAVIGATION_BAR,
        data: { isShownNavigationBar: value } as AppStateData,
      }),
  } as AppStateContextActions;
};
