import { NotificationData } from "../../types/notificationTypes";
import {
  NotificationContextState,
  NotificationContextAction,
  LOAD,
  CREATE,
  UPDATE,
  DELETE,
} from "./types";
import { AsyncReducer } from "../../hooks/useAsyncReducer";
import {
  createNotification,
  deleteNotification,
  getNotifications,
  updateNotification,
} from "../../api/notificationsApi";
import axios from "axios";
import { Updater } from "use-immer";
import { Immutable } from "immer";
import { getRegionById } from "../../api/regionsApi";

const sortNotificationsByDate = (notifications: NotificationData[]) => {
  const copy = [...notifications];
  return copy.sort((a, b) => {
    return new Date(a.date_created) > new Date(b.date_created) ? 1 : -1;
  });
};

export const notificationReducer: AsyncReducer<
  NotificationData[],
  NotificationContextState,
  NotificationContextAction,
  NotificationData
> = async (
  state: Immutable<NotificationContextState>,
  setState: Updater<NotificationContextState>,
  action: NotificationContextAction,
  axiosInstance = axios
) => {
  switch (action.type) {
    case LOAD:
      const notifications = await getNotifications(axiosInstance);
      await Promise.all(
        notifications.map(async (notification) => {
          notification.region =
            (await getRegionById(notification.region_id)) ?? undefined;
          notification.country = notification.region?.country ?? undefined;
        })
      );
      setState((state) => {
        state.data = sortNotificationsByDate(notifications);
      });

      return;
    case CREATE:
      const notification = await createNotification(
        action.data as NotificationData,
        axiosInstance
      );
      notification.region =
        (await getRegionById(notification.region_id)) ?? undefined;
      notification.country = notification.region?.country ?? undefined;
      setState((state) => {
        state.data.push(notification);
      });

      return;
    case UPDATE:
      const updatedNotification = action.data as NotificationData;
      await updateNotification(action.data as NotificationData, axiosInstance);
      updatedNotification.region =
        (await getRegionById(updatedNotification.region_id)) ?? undefined;
      updatedNotification.country =
        updatedNotification.region?.country ?? undefined;
      setState((state) => {
        state.data = state.data.map((notification) =>
          notification.id === updatedNotification.id
            ? updatedNotification
            : notification
        );
      });

      return;
    case DELETE:
      await deleteNotification(action.data as NotificationData, axiosInstance);
      setState((state) => {
        state.data = state.data.filter(
          (notification) =>
            notification.id !== (action.data as NotificationData).id
        );
      });

      return;
    default:
      return;
  }
};
