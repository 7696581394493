import { BaseContext, BaseContextAction } from "../../types/contextTypes";
import { RegionData, TimezoneData } from "../../types/regionTypes";

// Interfaces
export interface RegionsContextState extends BaseContext<RegionData[]> {
  searchText: string;
  initialRegions: RegionData[];
  timezones: TimezoneData[];
  countries: string[];
  selectedCountry?: string;
}

export interface RegionsContextActionData {
  searchText?: string;
  selectedCountry?: string;
}

export interface RegionsContextAction
  extends BaseContextAction<RegionsContextActionData> {}

export interface RegionsContextActions {
  init: () => Promise<void>;
  selectCountry: (country: string) => Promise<void>;
  searchByText: (searchText: string) => Promise<void>;
}

// Initial state
export const initialState: RegionsContextState = {
  data: [],
  isError: false,
  isLoading: false,
  searchText: "",
  initialRegions: [],
  timezones: [],
  countries: [],
};

export const initialActionsState: RegionsContextActions = {
  init: () => Promise.resolve(),
  selectCountry: () => Promise.resolve(),
  searchByText: () => Promise.resolve(),
};

// Actions
export const INIT = "INIT";
export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const SEARCH_TEXT = "SEARCH_TEXT";
