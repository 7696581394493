import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  root: {
    marginTop: theme.spacing.lg,

    [theme.fn.smallerThan('md')]: {
      marginTop: `-${theme.spacing.lg}`
    }
  },

  steps: {
    [theme.fn.smallerThan('md')]: {
      display: "none"
    }
  },


  content: {
    marginTop: theme.spacing.lg,
    marginBottom: theme.spacing.lg
  },
}));