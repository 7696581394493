import { AsyncDispatch } from "../../hooks/useAsyncReducer";
import { NotificationData } from "../../types/notificationTypes";
import {
  NotificationContextAction,
  LOAD,
  CREATE,
  UPDATE,
  DELETE,
  NotificationContextActions,
} from "./types";

export const createNotificationActions = (
  dispatch: AsyncDispatch<NotificationData, NotificationContextAction>
) => {
  return {
    load: () => dispatch({ type: LOAD }),
    create: (notification: NotificationData) =>
      dispatch({ type: CREATE, data: notification }),
    update: (notification: NotificationData) =>
      dispatch({
        type: UPDATE,
        data: notification,
      }),
    delete: (notification: NotificationData) =>
      dispatch({ type: DELETE, data: notification }),
  } as NotificationContextActions;
};
