import { RingProgress, Center, ThemeIcon, Text, useMantineTheme } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

export interface ProgressRingProps {
  progress: number;
  isSmall?: boolean;
}

export const ProgressRing: React.FC<ProgressRingProps> = ({progress, isSmall = false}) => {
  const isCompleted = progress === 100;
  const theme = useMantineTheme();

  return (
    <RingProgress
      size={isSmall ? 80 : undefined}
      sections={[
        { value: progress, color: isCompleted ? "teal" : `${theme.primaryColor}.3` },
      ]}
      label={
        isCompleted ? (
          <Center>
            <ThemeIcon color="teal" variant="light" radius="xl" size={(isSmall ? "sm" : "xl")}>
              <IconCheck size={isSmall ? 10 : 22} />
            </ThemeIcon>
          </Center>
        ) : (
          <Text
            ta="center"
            color="blue"
            weight={700}
            align="center"
            size={(isSmall ? "sm" : "xl")}
          >
            {progress}%
          </Text>
        )
      }
    />
  )
}