import { AsyncDispatch } from "../../hooks/useAsyncReducer";
import {
  INIT,
  RegionsContextAction,
  RegionsContextActionData,
  RegionsContextActions,
  SEARCH_TEXT,
  SELECT_COUNTRY,
} from "./types";

export const createRegionsActions = (
  dispatch: AsyncDispatch<RegionsContextActionData, RegionsContextAction>
) => {
  return {
    init: () => dispatch({ type: INIT }),
    selectCountry: (country: string) =>
      dispatch({ type: SELECT_COUNTRY, data: { selectedCountry: country } }),
    searchByText: (searchText: string) =>
      dispatch({ type: SEARCH_TEXT, data: { searchText } }),
  } as RegionsContextActions;
};
