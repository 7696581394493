import { Transition, Group, Aside as MantineAside, Text, useMantineTheme, Avatar, NavLink, CloseButton, Stack } from '@mantine/core';
import { IconLogout, IconReceipt2, IconUserEdit } from '@tabler/icons-react';
import { useStyles } from './Aside.styles';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { useAppStateContext, useAppStateActionsContext } from '../../../contexts/AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';


export default function Aside() {
  const { logout, user } = useAuth0();
  const userData = useUserContext();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { isShownNavigationBar } = useAppStateContext();
  const appStateActions = useAppStateActionsContext();
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  const onLogoutClicked = () => {
    logout();
  };

  useEffect(() => {
    if (isLargeScreen) {
      // If it's a large screen, we want to hide the mobile app shell
      appStateActions.setIsShownNavigationBar(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargeScreen]);


  if (!isLargeScreen) {
    return (
      <Transition mounted={isShownNavigationBar} transition={"slide-left"} duration={200} timingFunction="ease">
        {(stylesShow) => (
          <MantineAside p="md" style={stylesShow} className={classes.aside} hiddenBreakpoint="xl">
            <MantineAside.Section grow>
              <Group className={classes.header} position="left">
                <Avatar src={user?.picture} alt={user?.name} size="md" radius="lg" />
                <Stack spacing="0">
                  <Text>{`${userData.data?.first_name} ${userData.data?.last_name}`}</Text>
                  <Text mt="-5px" size="sm" c="gray.6" style={{letterSpacing: "-0.5px"}}>{user?.email}</Text>
                </Stack>
                <CloseButton
                  size="md"
                  onClick={() => { appStateActions.setIsShownNavigationBar(false) }}
                />
              </Group>
              <Stack ml="-4px">
                <NavLink label="Pricing" onClick={(event) => { navigate('/pricing'); appStateActions.setIsShownNavigationBar(false); }} icon={<IconReceipt2 stroke={1.0} />} />
                <NavLink label="Manage subscription" disabled={!userData.data?.subscription} onClick={(event) => { navigate('/subscription'); appStateActions.setIsShownNavigationBar(false); }} icon={<IconUserEdit stroke={1.0} />} />
                <NavLink label="Logout" onClick={(event) => { onLogoutClicked(); }} icon={<IconLogout stroke={1.0} />} />
              </Stack>
            </MantineAside.Section>
          </MantineAside>
        )}
      </Transition>

    );
  }

  return null;
}