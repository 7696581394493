import axios, { AxiosInstance } from "axios";

import { getApiConfig } from "../config";
import { ApiResponse } from "../types/apiTypes";
import { RegionData, TimezoneData } from "../types/regionTypes";

const regionsApiUrl = `${getApiConfig().baseUrl}/regions`;
const timezonesApiUrl = `${getApiConfig().baseUrl}/timezones`;
const countriesApiUrl = `${getApiConfig().baseUrl}/countries`;

export const getRegionsByCityName = async (
  country: string,
  cityName: string = "",
  axiosInstance: AxiosInstance = axios
) => {
  const url = `${regionsApiUrl}?term=${cityName}&country=${country}`;
  const response = await axiosInstance.get<ApiResponse<RegionData[]>>(url);

  if (!response.data.success) {
    console.error(`Failed GET ${url}`);
    return [] as RegionData[];
  }

  return response?.data.data as RegionData[];
};

export const getRegionById = async (
  id: string,
  axiosInstance: AxiosInstance = axios
) => {
  const url = `${regionsApiUrl}/${id}`;
  const response = await axiosInstance.get<ApiResponse<RegionData>>(url);

  if (!response.data.success) {
    console.error(`Failed GET ${url}`);
    return null;
  }

  return response?.data.data as RegionData;
};

export const getTimezones = async (
  country: string,
  axiosInstance: AxiosInstance = axios
) => {
  const url = `${timezonesApiUrl}?country=${country}`;
  const response = await axiosInstance.get<ApiResponse<TimezoneData[]>>(url);

  if (!response.data.success) {
    console.error(`Failed GET ${url}`);
    return [] as TimezoneData[];
  }

  return response?.data.data as TimezoneData[];
};

export const getCountries = async (axiosInstance: AxiosInstance = axios) => {
  const response = await axiosInstance.get<ApiResponse<string[]>>(
    countriesApiUrl
  );

  if (!response.data.success) {
    console.error(`Failed GET ${countriesApiUrl}`);
    return [] as string[];
  }

  return response?.data.data as string[];
};
