// NotificationContext.tsx
import { FC, ReactNode, createContext, useContext, useEffect } from "react";

import { initialActionsState, initialState } from "./types";
import useAsyncReducer from "../../hooks/useAsyncReducer";
import { regionsReducer } from "./reducer";
import { createRegionsActions } from "./createActions";

// Context
const RegionsContext = createContext(initialState);
const RegionsActionsContext = createContext(initialActionsState);

// Provider
const RegionsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(
    regionsReducer,
    initialState
  );
  const actions = createRegionsActions(dispatch);

  useEffect(() => {
    actions.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegionsContext.Provider value={state}>
      <RegionsActionsContext.Provider value={actions}>
        {children}
      </RegionsActionsContext.Provider>
    </RegionsContext.Provider>
  );
};

// Hooks
const useRegionsContext = () => useContext(RegionsContext);
const useRegionsActionsContext = () => useContext(RegionsActionsContext);

// Export
export { RegionsProvider, useRegionsContext, useRegionsActionsContext };
