import {FC, ReactNode} from 'react';
import {
  AppShell as MantineAppShell,
  useMantineTheme,
} from '@mantine/core';

import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Aside from "./Aside/Aside"
import { useStyles } from './AppShell.styles';


const AppShell: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <MantineAppShell
      classNames={{main: classes.main}}
      aside={<Aside />}
      header={<Header />}
      footer={<Footer />}>
      {children}
    </MantineAppShell>
  );
}

export default AppShell;