import {
  Button,
  Card,
  Group,
  MediaQuery,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { useSurveyContext } from "../../../contexts/SurveyContext";
import { ProgressRing } from "./ProgressRing";
import { useStyles } from "./SurveyNavigation.styles";
import { IconNotes } from "@tabler/icons-react";

const titleText = "Allergy survey";

const SurveyNavigation: React.FC = () => {
  const { progressPercentage, isCompleted } = useSurveyContext();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const descriptionText = (
    <Text align="center">
      We use <b>your answers</b> and our <b>clever AI</b> to create{" "}
      <b>personalized notifications</b> just for you!
    </Text>
  );

  const onSurveyClick = () => {
    navigate("/survey");
  };

  return (
    <Card className={classes.card}>
      <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
        <Group position="center">
          <ProgressRing progress={progressPercentage} />
          <Stack w="400px" align="center">
            <Title weight="normal" align="center">
              <IconNotes
                size="1.8rem"
                color={theme.colors.teal[4]}
                style={{ marginRight: "7px", position: "relative", top: "3px" }}
              />
              {titleText}
            </Title>
            <Text align="center" color="dimmed">
              {descriptionText}
            </Text>
            <Button mr="md" size="lg" onClick={onSurveyClick}>
              {`${isCompleted ? "Edit" : "Continue"} allergy survey`}
            </Button>
          </Stack>
        </Group>
      </MediaQuery>

      <MediaQuery largerThan="xs" styles={{ display: "none" }}>
        <Stack w="100%" align="center">
          <Group ml={!isCompleted ? "-xl" : "0"}>
            {!isCompleted && (
              <ProgressRing progress={progressPercentage} isSmall={true} />
            )}
            <Title
              order={2}
              className={classes.title}
              weight="bold"
              align="center"
            >
              {titleText}
            </Title>
          </Group>
          <Text className={classes.description} align="center" color="dimmed">
            {descriptionText}
          </Text>
          <Button
            className={classes.button}
            mr="md"
            size="lg"
            onClick={onSurveyClick}
          >
            {`${isCompleted ? "Edit" : "Continue"} allergy survey`}
          </Button>
        </Stack>
      </MediaQuery>
    </Card>
  );
};

export default SurveyNavigation;
