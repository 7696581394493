import axios, { AxiosInstance } from "axios";
import { getApiConfig } from "../config";
import { NotificationData } from '../types/notificationTypes';
import { ApiResponse } from "../types/apiTypes";

const apiUrl = `${getApiConfig().baseUrl}/notifications`;

export const getNotifications = async (axiosInstance: AxiosInstance = axios) => {
  const response = await axiosInstance.get<ApiResponse<NotificationData[]>>(apiUrl);

  if (!response.data.success) {
    throw new Error('Failed to fetch notifications');
  }

  return response.data.data as NotificationData[];
};

export const createNotification = async (notificationData: NotificationData, axiosInstance: AxiosInstance = axios) => {
  const response = await axiosInstance.post<ApiResponse<NotificationData>>(apiUrl, notificationData);

  if (!response.data.success) {
    throw new Error('Failed to create notification');
  }

  return response.data.data as NotificationData;
};

export const updateNotification = async (notificationData: NotificationData, axiosInstance: AxiosInstance = axios) => {
  const requestUrl = `${apiUrl}/${notificationData.id}`;
  const response = await axiosInstance.put<ApiResponse<NotificationData>>(requestUrl, notificationData);

  if (!response.data.success) {
    throw new Error('Failed to update notification');
  }

  return response.data.data as NotificationData;
};

export const deleteNotification = async (notificationData: NotificationData, axiosInstance: AxiosInstance = axios) => {
  const requestUrl = `${apiUrl}/${notificationData.id}`;
  const response = await axiosInstance.delete<ApiResponse>(requestUrl);

  if (!response.data.success) {
    throw new Error('Failed to delete notification');
  }

  return;
};
