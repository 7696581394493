import {
  MediaQuery,
  Card,
  Center,
  Stack,
  Title,
  Group,
  Switch,
  Badge,
  List,
  Button,
  Text,
} from "@mantine/core";
import {
  useUserActionsContext,
  useUserContext,
} from "../../contexts/UserContext";
import { useState, useEffect } from "react";
import {
  SubscriptionPlanData,
  getSubscriptionPlans,
  cancelSubscription,
} from "../../api/subscriptionApi";
import { useApiClient } from "../../contexts/AxiosContext";
import { useNavigate } from "react-router-dom";
import { useNotificationActionsContext } from "../../contexts/NotificationsContext";

export default function SubscriptionPage() {
  const userData = useUserContext();
  const userActions = useUserActionsContext();
  const notificationActions = useNotificationActionsContext();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const [userPlan, setUserPlan] = useState<SubscriptionPlanData>();

  useEffect(() => {
    if (!userData.data || !userData.data.subscription) return;

    getSubscriptionPlans(apiClient).then((plans) => {
      setUserPlan(
        plans.find(
          (plan) =>
            userData.data?.subscription?.subscription_plan_id ===
            plan.id.toString()
        )
      );
    });
  }, [userData]);

  const onCancelSubscriptionClicked = () => {
    if (!userData.data?.subscription?.subscription_id) return;

    cancelSubscription(
      userData.data.subscription.subscription_id,
      apiClient
    ).then(() => {
      userActions.load();
      notificationActions.load();
      navigate("/pricing");
    });
  };

  return (
    <MediaQuery smallerThan="sm" styles={{ margin: "0" }}>
      <Card mx="100px" my="lg" mih="93%">
        <Center>
          <Stack align="center">
            <Title align="center" order={2}>
              Hello {userData.data?.first_name} {userData.data?.last_name}!
            </Title>
            <Stack align="left" style={{ width: "100%" }}>
              <Text c="gray.6">
                Your subscription is{" "}
                <b>
                  {userData.data?.subscription?.date_end
                    ? "active"
                    : "not active"}
                </b>
                .
              </Text>
              <Text c="gray.6">
                Starting date:{" "}
                <b>
                  {new Date(
                    userData.data?.subscription?.date_created || ""
                  ).toLocaleDateString()}
                </b>
              </Text>
              <Text c="gray.6">
                End date:{" "}
                <b>
                  {new Date(
                    userData.data?.subscription?.date_end || ""
                  ).toLocaleDateString()}
                </b>
              </Text>
            </Stack>
            <Button
              onClick={() => onCancelSubscriptionClicked()}
              disabled={!userData.data?.subscription?.date_end}
              mt="sm"
              style={{ width: "220px" }}
              variant="white"
            >
              Cancel subscription
            </Button>
            <Button
              onClick={() => navigate("/pricing")}
              style={{ width: "220px" }}
            >
              See available plans
            </Button>
          </Stack>
        </Center>
      </Card>
    </MediaQuery>
  );
}
