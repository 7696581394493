import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import SurveyPage from "./pages/Survey";
import { useNotificationContext } from "./contexts/NotificationsContext";
import RegistrationPage from "./pages/Registration";
import AppShell from "./components/Layout/AppShell";
import { useRegionsContext } from "./contexts/RegionsContext";
import PricingPage from "./pages/Pricing";
import NotificationFormPage from "./pages/Home/Notifications/NotificationFormPage";
import SubscriptionPage from "./pages/Subscription";
import ThankYouPage from "./pages/ThankYou";

export default function App() {
  const regionsState = useRegionsContext();
  const notificationsState = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(notificationsState.isLoading);
  }, [regionsState.isLoading, notificationsState.isLoading]);

  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        style={{ position: "fixed" }}
        zIndex={9999}
        overlayBlur={2}
      />
      <AppShell>
        <Routes>
          <Route path="/survey" element={<SurveyPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/notification/:id" element={<NotificationFormPage />} />
          <Route path="/purchase-success" element={<ThankYouPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </AppShell>
    </>
  );
}
