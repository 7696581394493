interface AnalyticsEventParams {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

const Analytics = {
  trackEvent: function (params: AnalyticsEventParams) {
    // Now TypeScript knows about window.gtag
    const gtag = (window as any).gtag;
    if (typeof gtag === "function") {
      gtag("event", params.action, {
        event_category: params.category,
        event_label: params.label,
        value: params.value,
      });
    }
  },
};

export default Analytics;
