import axios from "axios";

import { AsyncReducer } from "../../hooks/useAsyncReducer";
import { RegionData } from "../../types/regionTypes";
import {
  RegionsContextState,
  RegionsContextAction,
  RegionsContextActionData,
  INIT,
  SEARCH_TEXT,
  SELECT_COUNTRY,
} from "./types";
import {
  getCountries,
  getRegionsByCityName,
  getTimezones,
} from "../../api/regionsApi";

let lastSearchText = "";

export const regionsReducer: AsyncReducer<
  RegionData[],
  RegionsContextState,
  RegionsContextAction,
  RegionsContextActionData
> = async (state, setState, action, axiosInstance = axios) => {
  switch (action.type) {
    case INIT:
      const countries = await getCountries(axiosInstance);
      setState((draft) => {
        draft.countries = countries;
      });

      return;

    case SELECT_COUNTRY:
      const selectedCountry = action.data?.selectedCountry || "";
      setState((draft) => {
        draft.selectedCountry = selectedCountry;
      });

      const timezones = await getTimezones(selectedCountry, axiosInstance);
      setState((draft) => {
        draft.timezones = timezones;
      });

      const initRegions = await getRegionsByCityName(
        selectedCountry,
        undefined,
        axiosInstance
      );
      setState((draft) => {
        draft.initialRegions = initRegions;
      });

      return;

    case SEARCH_TEXT:
      const newText = action.data?.searchText || "";
      setState((draft) => {
        draft.searchText = newText;
      });
      lastSearchText = newText;

      if (newText === "") {
        setState((draft) => {
          draft.data = [];
        });

        return;
      }

      // If searching for state look from existing regions cache
      if (newText.indexOf(",") !== -1) {
        return;
      }

      if (newText.length > 1) {
        const regions = await getRegionsByCityName(
          state.selectedCountry as string,
          action.data?.searchText,
          axiosInstance
        );
        if (lastSearchText === newText) {
          setState((draft) => {
            draft.data = regions;
          });
        }
      }

      return;
    default:
      return;
  }
};
