import React, { useEffect, useState } from "react";
import {
  Center,
  Container,
  MediaQuery,
  Modal,
  SimpleGrid,
  Title,
  Text,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconBell, IconPlus } from "@tabler/icons-react";

import { NotificationData } from "../../../types/notificationTypes";
import { emptyNotification } from "../../../types/notificationTypes";
import NotificationItem from "./NotificationItem";
import NotificationForm from "./NotificationForm";
import {
  useNotificationContext,
  useNotificationActionsContext,
} from "../../../contexts/NotificationsContext";
import { PopoverButton } from "../../../components/PopoverButton";
import { useStyles } from "./NotificationList.styles";
import { notifications as mantineNotifications } from "@mantine/notifications";
import {
  addNotificationToast,
  updateNotificationToast,
  deleteNotificationToast,
  disableNotificationToast,
  enableNotificationToast,
  buySubscribtionToast,
} from "../../../utils/mantineToasts";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const newNotificationLinkPrefix = "new";

const titleText = "Notifications";

const descriptionText = (
  <Text>
    Add <b>custom alerts</b> for your go-to <b>cities</b> and choose <b>when</b>{" "}
    and <b>how</b> you get the news – you're in control!
  </Text>
);

export interface NotiicationListParams {
  isEnabled: boolean;
  disabledHoverText: string;
  redirectUrl?: string;
  hasSubscription?: boolean;
}

const NotificationList: React.FC<NotiicationListParams> = ({
  isEnabled,
  disabledHoverText,
  redirectUrl,
  hasSubscription,
}) => {
  const [isShownNotificationForm, { open, close }] = useDisclosure(false);
  const { data } = useNotificationContext();
  const actions = useNotificationActionsContext();
  const [isUpdatingNotification, setIsUpdatingNotification] = useState(false);
  const [defaultNotification, setDefaultNotification] =
    useState<NotificationData>(emptyNotification);
  const [currentNotification, setCurrentNotification] =
    useState<NotificationData>(defaultNotification);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`);
  const { user } = useAuth0();

  useEffect(() => {
    setDefaultNotification({
      ...emptyNotification,
      email_address: user?.email,
      phone_number: data.length > 0 ? data[0].phone_number : "",
    });
  }, [data, user]);

  const onNotificationClicked = (notification: NotificationData) => {
    if (isLargeScreen) {
      openNotificationModal(notification);
    } else {
      openNotificationPage(notification);
    }
  };

  const openNotificationModal = (notification: NotificationData) => {
    setIsUpdatingNotification(notification.id !== undefined);
    setCurrentNotification({ ...notification });
    open();
  };

  const openNotificationPage = (notification: NotificationData) => {
    const adjustedId =
      notification.id === undefined
        ? newNotificationLinkPrefix
        : notification.id;
    navigate(`/notification/${adjustedId}`);
  };

  const onNotificationModalSubmit = async (notification: NotificationData) => {
    if (isUpdatingNotification) {
      await actions.update(notification);
      mantineNotifications.clean();
      mantineNotifications.show(updateNotificationToast);
    } else {
      await actions.create(notification);
      mantineNotifications.clean();
      mantineNotifications.show(addNotificationToast);

      if (!hasSubscription) {
        navigate("/pricing");
        mantineNotifications.show(buySubscribtionToast);
      }
    }
    close();
  };

  const onDeleteNotification = async (notification: NotificationData) => {
    await actions.delete(notification);
    mantineNotifications.clean();
    mantineNotifications.show(deleteNotificationToast);
  };

  const onToggleNotificationStatus = async (notification: NotificationData) => {
    mantineNotifications.clean();

    if (!notification.is_enabled && !hasSubscription) {
      navigate("/pricing");
      mantineNotifications.show(buySubscribtionToast);
      return;
    }

    await actions.update({
      ...notification,
      is_enabled: !notification.is_enabled,
    });
    mantineNotifications.show(
      !notification.is_enabled
        ? enableNotificationToast
        : disableNotificationToast
    );
  };

  const onAddNotificationClicked = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      if (isLargeScreen) {
        openNotificationModal(defaultNotification);
      } else {
        openNotificationPage(defaultNotification);
      }
    }
  };

  return (
    <Container my="lg">
      <MediaQuery largerThan="xs" styles={{ display: "none" }}>
        <Stack align="center">
          <Title order={2} weight="bold" align="center">
            <IconBell
              size="1.5rem"
              color={theme.colors.teal[4]}
              style={{ marginRight: "7px", position: "relative", top: "3.5px" }}
            />
            {titleText}
          </Title>
          <Text mb="lg" size="md" align="center" color="dimmed">
            {descriptionText}
          </Text>
        </Stack>
      </MediaQuery>
      <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
        <Stack align="center" mb="xl">
          <Title weight="normal" align="center">
            <IconBell
              size="1.8rem"
              color={theme.colors.teal[4]}
              style={{ marginRight: "7px", position: "relative", top: "3.5px" }}
            />
            {titleText}
          </Title>
          <Text maw="650px" align="center" color="dimmed">
            {descriptionText}
          </Text>
        </Stack>
      </MediaQuery>

      <Center>
        <SimpleGrid
          cols={data.length === 0 ? 1 : data.length === 1 ? 2 : 3}
          breakpoints={[{ maxWidth: "lg", cols: 1, verticalSpacing: "xs" }]}
        >
          {data.map((notification: NotificationData) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onEdit={() => onNotificationClicked(notification)}
              onDelete={() => onDeleteNotification(notification)}
              onToggleStatus={() => onToggleNotificationStatus(notification)}
            />
          ))}

          <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
            <PopoverButton
              style={{ flex: 1 }}
              h="140px"
              miw="200px"
              maw="450px"
              radius="lg"
              variant="outline"
              isEnabled={isEnabled}
              disabledHoverText={disabledHoverText}
              onClick={onAddNotificationClicked}
            >
              <IconPlus size={100} stroke={1} />
            </PopoverButton>
          </MediaQuery>

          <MediaQuery largerThan="xs" styles={{ display: "none" }}>
            <PopoverButton
              style={{ flex: 1 }}
              h="140px"
              miw="200px"
              maw="450px"
              radius="lg"
              variant="outline"
              isEnabled={isEnabled}
              disabledHoverText={disabledHoverText}
              onClick={onAddNotificationClicked}
            >
              <IconPlus size={100} stroke={1} />
            </PopoverButton>
          </MediaQuery>
        </SimpleGrid>
      </Center>

      <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
        <Modal
          opened={isShownNotificationForm}
          withCloseButton={false}
          onClose={close}
          centered
          classNames={{ body: classes.modalBody }}
          zIndex={9997} // higher then footer
          trapFocus={false}
          overlayProps={{
            opacity: 0.55,
            blur: 3,
          }}
        >
          <NotificationForm
            onSubmit={onNotificationModalSubmit}
            notification={currentNotification}
            isUpdatingNotification={isUpdatingNotification}
            onCancel={close}
          />
        </Modal>
      </MediaQuery>
    </Container>
  );
};

export default NotificationList;
