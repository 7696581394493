import { RegionData } from "./regionTypes";

export type ChannelType = "sms" | "email" | "whatsapp";
export type MsgFormatType = "regular" | "emoji" | "extended";

export interface NotificationData {
  id?: string;
  channel: ChannelType;
  region_id: string;
  zoned_time: string;
  timezone: string;
  msg_format: MsgFormatType;
  phone_number?: string;
  email_address?: string;
  is_enabled: boolean;
  date_created: string;

  country?: string;
  region?: RegionData;
}

export const emptyNotification: NotificationData = {
  channel: "sms" as ChannelType,
  region_id: "",
  zoned_time: "08:00",
  timezone: "",
  msg_format: "emoji" as MsgFormatType,
  phone_number: "",
  email_address: "",
  is_enabled: true,
  date_created: "",
};
