import { createContext, useContext, useEffect, useState } from "react";
import { LoadingOverlay } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { initialActionsState, initialState } from "./types";
import { userReducer } from "./reducer";
import useAsyncReducer from "../../hooks/useAsyncReducer";
import { createUserActions } from "./createActions";
import { useAuth0 } from "@auth0/auth0-react";

// Context
const UserContext = createContext(initialState);
const UserActionsContext = createContext(initialActionsState);

// Provider
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useAsyncReducer(
    userReducer,
    initialState
  );
  const actions = createUserActions(dispatch);

  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const {isAuthenticated} = useAuth0();

  useEffect(() => {
    actions.load().then(() => {
      setIsInitialized(isAuthenticated);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialized && !state.data) {
      navigate('/register');
    }
  }, [
    isInitialized,
    state,
    navigate,
  ]);

  return (
    <UserContext.Provider value={state}>
      <UserActionsContext.Provider value={actions}>
        <LoadingOverlay visible={!isInitialized} />
        { children }
      </UserActionsContext.Provider>
    </UserContext.Provider>
  );
};

// Hooks
export const useUserContext = () => useContext(UserContext);
export const useUserActionsContext = () => useContext(UserActionsContext);
