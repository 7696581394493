import { useNavigate, useParams } from "react-router-dom";
import { useNotificationContext, useNotificationActionsContext } from "../../../contexts/NotificationsContext";
import NotificationForm from "./NotificationForm";
import { NotificationData } from "../../../types/notificationTypes";
import { emptyNotification as defaultNotification } from "../../../types/notificationTypes";
import { notifications as mantineNotifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { updateNotificationToast, addNotificationToast } from "../../../utils/mantineToasts";
import { Card } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";

//used on mobile
export default function NotificationFormPage() {
  const { id } = useParams();
  const { data } = useNotificationContext();
  const actions = useNotificationActionsContext();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [isUpdatingNotification, setIsUpdatingNotification] = useState(false);

  const [currentNotification, setCurrentNotification] =
    useState<NotificationData>(defaultNotification);

  useEffect(() => {
    setIsUpdatingNotification(id !== "new")
    const currentNotificationValue: NotificationData = data.find((notification) => notification.id === id) ?? {...defaultNotification, email_address: user?.email, phone_number: data.length > 0 ? data[0].phone_number : ""};
    setCurrentNotification({...currentNotificationValue})
  }, [data, id, user])

  const onNotificationSubmit = async (notification: NotificationData) => {
    if (isUpdatingNotification) {
      await actions.update(notification);
      mantineNotifications.clean();
      mantineNotifications.show(updateNotificationToast);
    } else {
      await actions.create(notification);
      mantineNotifications.clean();
      mantineNotifications.show(addNotificationToast);
    }
    navigate("/")
  };

  const onClose = () => {
    navigate("/");
  }

  return (
    <Card>
      <NotificationForm
        onSubmit={onNotificationSubmit}
        notification={currentNotification}
        isUpdatingNotification={isUpdatingNotification}
        onCancel={onClose}
      />
    </Card>
  )
}