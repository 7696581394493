import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { MantineProvider } from "@mantine/core";
import { BrowserRouter as Router } from "react-router-dom";

import { getAuthConfig } from "./config";
import App from "./App";
import { AxiosInstanceProvider } from "./contexts/AxiosContext";
import { NotificationProvider } from "./contexts/NotificationsContext";
import { SurveyProvider } from "./contexts/SurveyContext";
import { AppStateProvider } from "./contexts/AppContext";
import { mainTheme } from "./theme/main-theme";
import { RegionsProvider } from "./contexts/RegionsContext";
import { Notifications as MantineNotifications} from "@mantine/notifications";
import { UserProvider } from "./contexts/UserContext";

const config = getAuthConfig();

const providerConfig: Auth0ProviderOptions = {
  domain: config.domain,
  clientId: config.clientId,
  cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: window.location.origin,
    responseType: config.responseType,
    scope: config.scope,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const addReact = (children: React.ReactNode) => (
  <React.StrictMode>{children}</React.StrictMode>
);

const addInfrastructure = (children: React.ReactNode) => (
  <Auth0Provider {...providerConfig}>
    <MantineProvider theme={mainTheme} withGlobalStyles withNormalizeCSS>
      <AxiosInstanceProvider>
        <MantineNotifications zIndex={9998} />
        <Router>{children}</Router>
      </AxiosInstanceProvider>
    </MantineProvider>
  </Auth0Provider>
);

const addContexts = (children: React.ReactNode) => (
  <UserProvider>
    <AppStateProvider>
      <SurveyProvider>
        <NotificationProvider>
          <RegionsProvider>
            {children}
          </RegionsProvider>
        </NotificationProvider>
      </SurveyProvider>
    </AppStateProvider>
  </UserProvider>
);

const appNode = addReact(addInfrastructure(addContexts(<App></App>)));

root.render(appNode);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
