import { FC, ReactNode, createContext, useContext, useReducer } from "react";
import { createAppStateActions } from "./createActions";
import { AppStateContextActions, initialActionsState, initialState} from "./types";
import { appStateReducer } from "./reducer";

const AppStateContext = createContext(initialState);

const AppStateActionsContext = createContext<AppStateContextActions>(initialActionsState);

const AppStateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(appStateReducer, initialState);
  const actions = createAppStateActions(dispatch);

  return (
    <AppStateContext.Provider value={state}>
      <AppStateActionsContext.Provider value={actions}>
        {children}
      </AppStateActionsContext.Provider>
    </AppStateContext.Provider>
  );
};

const useAppStateContext = () => useContext(AppStateContext);
const useAppStateActionsContext = () =>
  useContext(AppStateActionsContext);

export {
  AppStateProvider,
  useAppStateContext,
  useAppStateActionsContext,
};
