import { NotificationProps as ToastProps } from "@mantine/notifications";

const defaultToastProps: ToastProps = {
  color: "green",
  title: "Your action was successful!",
  message: "",
  radius: "md",
};

export const buySubscribtionToast: ToastProps = {
  ...defaultToastProps,
  title: "Please buy subscription to activate your notifications!",
};

export const addNotificationToast: ToastProps = {
  ...defaultToastProps,
  title: "Your notification was successfully added!",
};

export const updateNotificationToast: ToastProps = {
  ...defaultToastProps,
  title: "Your notification was successfully edited!",
};

export const deleteNotificationToast: ToastProps = {
  ...defaultToastProps,
  title: "Your notification was successfully deleted!",
};

export const disableNotificationToast: ToastProps = {
  ...defaultToastProps,
  title: "Your notification was successfully disabled!",
};

export const enableNotificationToast: ToastProps = {
  ...defaultToastProps,
  title: "Your notification was successfully enabled!",
};
