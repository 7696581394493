import { createStyles, Container, Group, ActionIcon, rem, Footer as MantineFooter, Text, Anchor, useMantineTheme, SimpleGrid, Grid } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { useStyles } from './Footer.styles';
import { getLegalAgreementsConfig } from '../../../config';

const emailSupportUrl = "mailto:support@ailergic.com";

export default function Footer() {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <MantineFooter className={classes.footer} height={60}>
      <Container className={classes.footerContent} size="xl">
        
          <Text size="sm" color={theme.colors.gray[6]}  className={classes.textResize} align="left">
            © 2023 Ailergic. All rights reserved.
          </Text>
        
        <Grid grow>
          <Grid.Col span="content">
            <Anchor href={getLegalAgreementsConfig().privacyPolicy} target="_blank" size="sm" color={theme.colors.gray[6]} className={classes.textResize} align="right">
              Privacy Policy
            </Anchor></Grid.Col>
          <Grid.Col span="content">
            <Anchor href={getLegalAgreementsConfig().termsAndConditions} target="_blank" size="sm" color={theme.colors.gray[6]} className={classes.textResize} align="right">
              Terms of Service
            </Anchor></Grid.Col>
          <Grid.Col span="content">
            <Anchor href={emailSupportUrl} target="_blank" size="sm" color={theme.colors.gray[6]} className={classes.textResize} align="right">
              Contact Us
            </Anchor></Grid.Col>
        </Grid>


      </Container>
    </MantineFooter>
  );
}