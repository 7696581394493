// NotificationContext.tsx
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";

import {
  initialActionsState,
  initialState,
} from "./types";
import useAsyncReducer from "../../hooks/useAsyncReducer";
import { notificationReducer } from "./reducer";
import { createNotificationActions } from "./createActions";

// Context
const NotificationContext = createContext(initialState);
const NotificationActionsContext = createContext(initialActionsState);

// Provider
const NotificationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(notificationReducer, initialState);
  const actions = createNotificationActions(dispatch);

  useEffect(() => {
    actions.load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationContext.Provider value={state}>
      <NotificationActionsContext.Provider value={actions}>
        {children}
      </NotificationActionsContext.Provider>
    </NotificationContext.Provider>
  );
};

// Hooks
const useNotificationContext = () => useContext(NotificationContext);
const useNotificationActionsContext = () =>
  useContext(NotificationActionsContext);

// Export
export {
  NotificationProvider,
  useNotificationContext,
  useNotificationActionsContext,
};
