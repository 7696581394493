import { MantineThemeOverride, rem } from '@mantine/core';

export const mainTheme: MantineThemeOverride = {
  colorScheme: 'light',
  primaryColor: 'orange',
  colors: {
    dark: [
      '#d5d7e0',
      '#acaebf',
      '#8c8fa3',
      '#666980',
      '#4d4f66',
      '#34354a',
      '#2b2c3d',
      '#1d1e30',
      '#0c0d21',
      '#01010a',
    ],
    teal: [
      '#91b4b8',
      '#7ba4aa',
      '#65959c',
      '#4f868d',
      '#39777f',
      '#236871',
      '#205e66',
      '#1c535a',
      '#19494f'
    ],
    // orange: [
    //   '#e5bfa1',
    //   '#deaf8a',
    //   '#d79e73',
    //   '#d18e5b',
    //   '#ca7e44',
    //   '#c46e2c',
    //   '#bd5e15',
    //   '#aa5513',
    //   '#974b11',
    //   '#84420f'
    // ],
    orange: [
      '#ffd9b3',
      '#ffd0a0',
      '#ffc78d',
      '#ffbd7a',
      '#ffb467',
      '#ffaa54',
      '#e6913b',
      '#cc8134',
      '#e6913b',
      '#cc8134',
      //'#b3712e',
    ],
    // yellow: [
    //   '#fdfabd',
    //   '#fdf9ad',
    //   '#fcf79c',
    //   '#fcf68c',
    //   '#fbf57b',
    //   '#fbf36b',
    //   '#faf25a',
    //   '#e1da51',
    //   '#c8c248',
    //   '#afa93f'
    // ],
    yellow: [
      '#ffe2bb',
      '#ffdbaa',
      '#ffd499',
      '#ffcd88',
      '#ffc577',
      '#ffbe66',
      '#ffb755',
      '#e6a54d',
      '#cc9244',
      '#b3803b'
    ],
    red: [
      '#f2dfdb',
      '#ebceca',
      '#e5beb8',
      '#deaea6',
      '#d79e94',
      '#d18e82',
      '#ca7d71',
      '#c46d5f',
      '#bd5d4d',
      '#aa5445'
    ],
    pink: [
      '#ffe1e1',
      '#ffdada',
      '#ffd2d2',
      '#ffcbcb',
      '#ffc3c3',
      '#ffbcbc',
      '#ffb4b4',
      '#e6a2a2',
      '#cc9090',
      '#b37e7e',
    ],
    brown: [
      '#ffcbcb',
      '#ffc3c3',
      '#ffbcbc',
      '#ffb4b4',
      '#e6a2a2',
      '#cc9090',
      '#b37e7e',
      '#996c6c',
      '#805a5a',
      '#664848',
    ]
  },
  fontFamily: 'Ubuntu, sans-serif',
  //fontFamilyMonospace: 'Monaco, Courier, monospace',
  //headings: { fontFamily: 'Greycliff CF, sans-serif' },
  defaultRadius: 'xl',
  activeStyles: { transform: 'scale(0.95)', opacity: 1 },
  cursorType: "pointer",
  loader: 'dots',
  components: {
    AppShell: {
      styles: (theme) => ({
        main: {
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'repeat',
          //backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3]
          //backgroundImage: theme.fn.gradient({ from: 'indigo', to: 'white', deg: 45 })
          //backgroundImage: 'url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%222000%22 height=%221400%22%3E%3Cg filter=%22url(%23a)%22%3E%3Cpath fill=%22%23CAB8FF%22 d=%22M-1000-700h4000v2800h-4000z%22%2F%3E%3Cpath d=%22M157-413-354 764l1164 293 278-296%22 fill=%22%23C1FFD7%22%2F%3E%3Cpath d=%22M1145-163 244 251l1198 1300 378-666%22 fill=%22%23CAB8FF%22%2F%3E%3Cpath d=%22M913 470 775 1679l1073 263 488-942%22 fill=%22%23CAB8FF%22%2F%3E%3Cpath d=%22m821 300-1320 852 604 598 948-1164%22 fill=%22%23C1FFD7%22%2F%3E%3Cpath d=%22M1366 1139 281 1898l735 742 859-1398%22 fill=%22%23B5DEFF%22%2F%3E%3Cpath d=%22M1946 1228 801 2671l1148 224 380-625%22 fill=%22%23fcffa6%22%2F%3E%3C%2Fg%3E%3Cpath fill=%22%23fff%22 filter=%22url(%23b)%22 d=%22M0 0h2000v1400H0z%22%2F%3E%3Cdefs%3E%3Cfilter id=%22b%22 x=%22-800%22 y=%22-560%22 width=%222800%22 height=%221960%22 filterUnits=%22userSpaceOnUse%22 primitiveUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22linearRGB%22%3E%3CfeTurbulence type=%22fractalNoise%22 baseFrequency=%22.13%22 numOctaves=%224%22 seed=%2215%22 stitchTiles=%22no-stitch%22 x=%220%22 y=%220%22 width=%222000%22 height=%221400%22 result=%22turbulence%22%2F%3E%3CfeSpecularLighting surfaceScale=%2210%22 specularConstant=%22.37%22 specularExponent=%2220%22 lighting-color=%22%23fff%22 x=%220%22 y=%220%22 width=%222000%22 height=%221400%22 in=%22turbulence%22 result=%22specularLighting%22%3E%3CfeDistantLight azimuth=%223%22 elevation=%22100%22%2F%3E%3C%2FfeSpecularLighting%3E%3C%2Ffilter%3E%3Cfilter id=%22a%22 x=%22-200%22 y=%22-200%22 width=%222400%22 height=%221800%22 filterUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22sRGB%22%3E%3CfeFlood flood-opacity=%220%22 result=%22BackgroundImageFix%22%2F%3E%3CfeBlend in=%22SourceGraphic%22 in2=%22BackgroundImageFix%22 result=%22shape%22%2F%3E%3CfeGaussianBlur stdDeviation=%22200%22 result=%22effect1_foregroundBlur_1_2%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3C%2Fsvg%3E")'
          //backgroundImage: 'url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%222000%22 height=%221400%22%3E%3Cg filter=%22url(%23a)%22%3E%3Cpath fill=%22%2390AACB%22 d=%22M-1000-700h4000v2800h-4000z%22%2F%3E%3Cpath d=%22m564-305-957 25L657 1201l361-810%22 fill=%22%23FEF1E6%22%2F%3E%3Cpath d=%22M1283-274 360 895l1405 433 148-38%22 fill=%22%23F9D5A7%22%2F%3E%3Cpath d=%22m1196 531-169 992 45 359 1360-377%22 fill=%22%23F9D5A7%22%2F%3E%3Cpath d=%22m217 572-484 252L808 2054l483-504%22 fill=%22%23FFB085%22%2F%3E%3Cpath d=%22M1430 1215 119 1967l56 833 1477-813%22 fill=%22%23FEF1E6%22%2F%3E%3Cpath d=%22M1796 1194 986 2322l516 510 977-691%22 fill=%22%23F9D5A7%22%2F%3E%3C%2Fg%3E%3Cpath fill=%22%23fff%22 filter=%22url(%23b)%22 d=%22M0 0h2000v1400H0z%22%2F%3E%3Cdefs%3E%3Cfilter id=%22b%22 x=%22-800%22 y=%22-560%22 width=%222800%22 height=%221960%22 filterUnits=%22userSpaceOnUse%22 primitiveUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22linearRGB%22%3E%3CfeTurbulence type=%22fractalNoise%22 baseFrequency=%22.13%22 numOctaves=%224%22 seed=%2215%22 stitchTiles=%22no-stitch%22 x=%220%22 y=%220%22 width=%222000%22 height=%221400%22 result=%22turbulence%22%2F%3E%3CfeSpecularLighting surfaceScale=%2210%22 specularConstant=%22.37%22 specularExponent=%2220%22 lighting-color=%22%23fff%22 x=%220%22 y=%220%22 width=%222000%22 height=%221400%22 in=%22turbulence%22 result=%22specularLighting%22%3E%3CfeDistantLight azimuth=%223%22 elevation=%22100%22%2F%3E%3C%2FfeSpecularLighting%3E%3C%2Ffilter%3E%3Cfilter id=%22a%22 x=%22-200%22 y=%22-200%22 width=%222400%22 height=%221800%22 filterUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22sRGB%22%3E%3CfeFlood flood-opacity=%220%22 result=%22BackgroundImageFix%22%2F%3E%3CfeBlend in=%22SourceGraphic%22 in2=%22BackgroundImageFix%22 result=%22shape%22%2F%3E%3CfeGaussianBlur stdDeviation=%22200%22 result=%22effect1_foregroundBlur_1_2%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3C%2Fsvg%3E")'
          //backgroundImage: 'url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%222000%22 height=%221400%22%3E%3Cg filter=%22url(%23a)%22%3E%3Cpath fill=%22%2390AACB%22 d=%22M-1000-700h4000v2800h-4000z%22%2F%3E%3Cpath d=%22m564-305-957 25L657 1201l361-810%22 fill=%22%23FEF1E6%22%2F%3E%3Cpath d=%22M1283-274 360 895l1405 433 148-38%22 fill=%22%23F9D5A7%22%2F%3E%3Cpath d=%22m1196 531-169 992 45 359 1360-377%22 fill=%22%23F9D5A7%22%2F%3E%3Cpath d=%22m217 572-484 252L808 2054l483-504%22 fill=%22%23FFB085%22%2F%3E%3Cpath d=%22M1430 1215 119 1967l56 833 1477-813%22 fill=%22%23FEF1E6%22%2F%3E%3Cpath d=%22M1796 1194 986 2322l516 510 977-691%22 fill=%22%23F9D5A7%22%2F%3E%3C%2Fg%3E%3Cpath fill=%22%23fff%22 filter=%22url(%23b)%22 d=%22M0 0h2000v1400H0z%22%2F%3E%3Cdefs%3E%3Cfilter id=%22b%22 x=%22-800%22 y=%22-560%22 width=%222800%22 height=%221960%22 filterUnits=%22userSpaceOnUse%22 primitiveUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22linearRGB%22%3E%3CfeTurbulence type=%22fractalNoise%22 baseFrequency=%22.13%22 numOctaves=%224%22 seed=%2215%22 stitchTiles=%22no-stitch%22 x=%220%22 y=%220%22 width=%222000%22 height=%221400%22 result=%22turbulence%22%2F%3E%3CfeSpecularLighting surfaceScale=%2210%22 specularConstant=%22.37%22 specularExponent=%2220%22 lighting-color=%22%23fff%22 x=%220%22 y=%220%22 width=%222000%22 height=%221400%22 in=%22turbulence%22 result=%22specularLighting%22%3E%3CfeDistantLight azimuth=%223%22 elevation=%22100%22%2F%3E%3C%2FfeSpecularLighting%3E%3C%2Ffilter%3E%3Cfilter id=%22a%22 x=%22-200%22 y=%22-200%22 width=%222400%22 height=%221800%22 filterUnits=%22userSpaceOnUse%22 color-interpolation-filters=%22sRGB%22%3E%3CfeFlood flood-opacity=%220%22 result=%22BackgroundImageFix%22%2F%3E%3CfeBlend in=%22SourceGraphic%22 in2=%22BackgroundImageFix%22 result=%22shape%22%2F%3E%3CfeGaussianBlur stdDeviation=%22200%22 result=%22effect1_foregroundBlur_1_2%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3C%2Fsvg%3E")',
          //backgroundImage: 'url("/background1.svg")',
          backgroundColor: '#91b4b8',
          //backgroundColor: '#DDE3E3',
          
          [theme.fn.smallerThan('xs')]: {
            paddingBottom: theme.spacing.md
          }
        }
      })
    },
    Select: {
      styles: (theme) => ({
        dropdown: {
          borderRadius: theme.radius.lg
        }
      })
    },
    MultiSelect: {
      styles: (theme) => ({
        dropdown: {
          borderRadius: theme.radius.lg
        }
      })
    },
    Header: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.gray[2],
          opacity: 0.9,
          [theme.fn.smallerThan('sm')]: {
            opacity: 1.0,
          },
        }
      })
    },
    Footer: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.gray[2],
          opacity: 0.9
        }
      })
    },
    Card: {
      styles: (theme) => ({
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          borderRadius: theme.radius.lg
        }
      })
    },
    Text: {
      styles: (theme) => ({
        root: {
          color: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.gray[2]
        }
      })
    },
    Stepper: {
      styles: (theme) => ({
        steps: {
          margin: `0 ${theme.spacing.lg}`,

          [theme.fn.smallerThan('sm')]: {
            margin: 0
          }
        },

        step: {
          transition: 'transform 150ms ease',
      
          '&[data-progress]': {
            transform: 'scale(1.05)',
          },
        },

        stepIcon: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
        },
        
        separator: {
          height: rem(2),
          backgroundColor: theme.colors.gray[4],
        }
      })
    },
    Progress: {
      styles: (theme) => ({
        root: {
          [theme.fn.largerThan('sm')]: {
            display: "none"
          },
      
          width: "120%",
          marginLeft: rem("-25px"),
          marginBottom: theme.spacing.md,
        },
    
        bar: {
          transition: "width 1s ease"
        }
      })
    }
  },
  other: {
    notificationForm: {
      inputFieldWidth: "260px"
    }
  }
};