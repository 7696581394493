import { Card, MediaQuery, Stack } from "@mantine/core";

import NotificationList from "./Notifications/NotificationList";
import SurveyNavigation from "./SurveyNavigation/SurveyNavigation";
import { useSurveyContext } from "../../contexts/SurveyContext";
import { useNotificationContext } from "../../contexts/NotificationsContext";
import { useUserContext } from "../../contexts/UserContext";
import PricingBanner from "./PricingBanner/PricingBanner";

const completeAllergySurveyText = "Please complete allergy survey";
const notificationCountLimitText =
  "You have reached the limit of 3 notifications";

function HomePage() {
  const { isCompleted: isSurveyCompleted } = useSurveyContext();
  const notifications = useNotificationContext();
  const userData = useUserContext();

  const isUserSubscribed: boolean =
    !!userData.data?.subscription?.subscription_id;
  const isSubscriptionActive: boolean = !!userData.data?.subscription?.date_end;
  let redirectUrl = !isUserSubscribed
    ? "/pricing"
    : !isSubscriptionActive
    ? "/subscription"
    : undefined;

  if (!isUserSubscribed && notifications.data.length === 0) {
    redirectUrl = undefined;
  }

  const notificationList = (
    <NotificationList
      isEnabled={isSurveyCompleted && notifications.data.length < 3}
      disabledHoverText={
        notifications.data.length < 3
          ? completeAllergySurveyText
          : notificationCountLimitText
      }
      redirectUrl={redirectUrl}
      hasSubscription={isUserSubscribed}
    />
  );

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Stack mx="100px" my="lg" align="center" mb="lg">
          {isSurveyCompleted && !isSubscriptionActive && (
            <Card w="100%">
              <PricingBanner />
            </Card>
          )}
          <Card w="100%">
            <SurveyNavigation />
          </Card>
          <Card mih="45vh" w="100%">
            {notificationList}
          </Card>
        </Stack>
      </MediaQuery>

      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Stack align="center">
          {isSurveyCompleted && !isSubscriptionActive && (
            <Card w="100%">
              <PricingBanner />
            </Card>
          )}
          <Card w="100%">
            <SurveyNavigation />
          </Card>
          <Card w="100%">{notificationList}</Card>
        </Stack>
      </MediaQuery>
    </>
  );
}

export default HomePage;
