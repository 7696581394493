import { BaseContext, BaseContextAction } from "../../types/contextTypes";
import { UserData } from "../../types/userTypes";

// Interfaces
export interface UserContextState extends BaseContext<UserData | undefined> {}

export interface UserContextActions {
  create: (user: UserData) => Promise<void>;
  load: () => Promise<void>;
}

export type UserContextActionData = UserData | undefined;

export interface UserContextAction
  extends BaseContextAction<UserContextActionData> {}

// Initial states
export const initialState: UserContextState = {
  data: undefined,
  isError: false,
  isLoading: false
};

export const initialActionsState: UserContextActions = {
  create: () => Promise.resolve(),
  load: () => Promise.resolve()
}

// Actions
export const CREATE_USER = 'CREATE_USER';
export const LOAD_USER = 'LOAD_USER'; 
