import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";
import { LoadingOverlay } from "@mantine/core";

const AxiosInstanceContext = createContext<AxiosInstance | null>(null);

export const AxiosInstanceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading, isAuthenticated, loginWithRedirect, getIdTokenClaims, logout } =
  useAuth0();
  const apiClientRef = useRef(axios.create());
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      //apiClientRef.current.defaults.headers.common['Authorization'] = '';
      //delete apiClientRef.current.defaults.headers.common['Authorization'];

      loginWithRedirect();

      setIsReady(false);
    } else {
      getIdTokenClaims().then((claims) => {
        const token = claims?.__raw;
        apiClientRef.current.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        setIsReady(true);
      });
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
    setIsReady
  ])

  useEffect(() => {
    apiClientRef.current.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }, [logout]);

  return (
    <AxiosInstanceContext.Provider value={apiClientRef.current}>
      {isReady ? children : <LoadingOverlay visible={true} />}
    </AxiosInstanceContext.Provider>
  );
};

export const useApiClient = () => {
  const apiClient = useContext(AxiosInstanceContext);
  if (!apiClient) {
    throw new Error("useApiClient must be used within a AxiosInstanceProvider");
  }
  return apiClient;
};