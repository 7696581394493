import { IconBrandWhatsapp, IconDeviceMobileMessage, IconMail, IconMessage, IconMoodSmile, IconMessagePlus } from "@tabler/icons-react";
import { Text } from "@mantine/core"

import { ChannelType, MsgFormatType, NotificationData } from "../../../types/notificationTypes";

export const channelIconMap: Record<ChannelType, JSX.Element> = {
  whatsapp: <IconBrandWhatsapp size="1.4rem" stroke={1.5} />,
  sms: <IconDeviceMobileMessage size="1.4rem" stroke={1.5} />,
  email: <IconMail size="1.4rem" stroke={1.5} />,
};

export const channelOptions: { value: ChannelType; label: string }[] = [
  { value: "sms", label: "SMS" },
  { value: "whatsapp", label: "WhatsApp" },
  { value: "email", label: "Email" },
];

export const msgFormatIconMap: Record<MsgFormatType, JSX.Element> = {
  regular: <IconMessage size="1.4rem" stroke={1.5} />,
  emoji: <IconMoodSmile size="1.4rem" stroke={1.5} />,
  extended: <IconMessagePlus size="1.4rem" stroke={1.5} />,
};

export const msgFormatOptions: { value: MsgFormatType; label: string }[] = [
  { value: "regular", label: "Regular" },
  { value: "emoji", label: "Emoji" },
  { value: "extended", label: "Extended" },
];

export type NotificationFormProps = {
  isUpdatingNotification: boolean;
  notification: NotificationData;
  onSubmit: (values: NotificationData) => void;
  editingNotification?: boolean;
  onCancel?: () => void;
};

const listMarginLeft = "sm";

export const formatToMessage: Record<MsgFormatType, JSX.Element> = {
  "emoji": (
    <>
      <Text><b>Daily Allergic Alert</b></Text>
      <Text>🏙️ <b>Location:</b> Austin, TX</Text>
      <Text>🌳 <b>Pollen Count:</b> Low</Text>
      <Text>🤧 <b>Potential Symptoms:</b></Text>      
      <Text ml={listMarginLeft}> • Itchy Nose</Text>
      <Text ml={listMarginLeft}> • Sneezing</Text>
      <Text ml={listMarginLeft}> • Watery Eyes</Text>
      <Text>❎ <b>Cross-allergies Alert:</b></Text>
      <Text ml={listMarginLeft}> • Sunflower Seeds</Text>
      <Text ml={listMarginLeft}> • Fennel Seeds</Text>
      <Text>🩹 <b>Precautionary Measures:</b></Text>
      <Text ml={listMarginLeft}> • Antihistamines</Text>
      <Text ml={listMarginLeft}> • Eye Drops</Text>
      <Text ml={listMarginLeft}> • Nasal Filters</Text>
      <Text><b>Tip of the day:</b> Limit exposure to allergens for a symptom-free day!</Text>
    </>
  ),
  "regular": (
    <>
      <Text><b>Austin, TX</b> - Daily Allergic Alert</Text>
      <Text> <b>• Pollen Count:</b> Low today</Text>
      <Text> <b>• Potential Symptoms:</b> Itchy Nose, Sneezing, Watery Eyes</Text>
      <Text> <b>• Cross-allergies:</b> Sunflower Seeds, Fennel Seeds</Text>
      <Text> <b>• Preparation Measures:</b> Antihistamines, Eye Drops, Nasal Filters</Text>
      <Text> <b>• Tip:</b> Limit exposure to allergens for a symptom-free day!</Text>
    </>
  ),
  "extended": (
    <>
      <Text><b>Austin, TX</b> - Extended Daily Allergic Alert</Text>
      <Text> <b>Pollen Count:</b> Low. This indicates a break for pollen allergy sufferers, but remember, even low counts can trigger symptoms in sensitive individuals.</Text>
      <Text> <b>Potential Symptoms:</b> Itchy nose, sneezing, and watery eyes may still occur, so stay prepared.</Text>
      <Text> <b>Cross-allergies:</b> If you're sensitive to sunflower seeds and fennel seeds, you may experience heightened allergy symptoms.</Text>
      <Text> <b>Preparation Measures:</b></Text>
      <Text ml={listMarginLeft}> • Use <b>antihistamines</b> to combat allergic reactions.</Text>
      <Text ml={listMarginLeft}> • Apply <b>eye drops</b> to soothe irritated eyes.</Text>
      <Text ml={listMarginLeft}> • Consider using <b>nasal filters</b> to shield against inhaled allergens.</Text>
      <Text> <b>Tip:</b> Limit exposure to allergens as much as possible. Stay indoors during peak pollen times, and keep your living areas clean. Prevention is key for a symptom-free day. Have a great day!</Text>
    </>
  ),
}