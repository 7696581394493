import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: `calc(${theme.spacing.xs} * 0.65)`,//theme.spacing.xs,
    marginBottom: `calc(${theme.spacing.xs})`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  aside: {
    minHeight: "100%",
    top: 0,
    backgroundColor: theme.colors.gray[2],
    width: "fit-content", 
    // less then footer
    zIndex: 9995,
  },
}));