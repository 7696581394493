import { createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme) => ({
  card: {
    background: "transparent",
    [theme.fn.smallerThan("sm")]: {
      background: "transparent"
    }
  },

  title: {

  },

  progressRing: {

  },

  description: {

  },

  button: {

  }
}));