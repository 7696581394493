import { BaseContextAction } from "../../types/contextTypes";

export interface AppStateData {
  isShownNavigationBar: boolean;
}

export const initialState: AppStateData = {
  isShownNavigationBar: false,
};

export interface AppStateContextAction
  extends BaseContextAction<AppStateData> {}

export interface AppStateContextActions {
  setIsShownNavigationBar: (value: boolean) => void;
  // add more later if needed
}

export const initialActionsState: AppStateContextActions = {
  setIsShownNavigationBar: (value: boolean) => {},
};

export const SET_IS_SHOWN_NAVIGATION_BAR = "SET_IS_SHOWN_NAVIGATION_BAR";
