import axios, { AxiosError, AxiosInstance } from "axios";

import { getApiConfig } from "../config";
import { UserData } from "../types/userTypes";

const apiUrl = `${getApiConfig().baseUrl}/user`;

export const getUser = async (axiosInstance: AxiosInstance = axios) => {
  try {
    const response = await axiosInstance.get(apiUrl);
    const data = response.data.data as UserData;
    // data.subscription = {
    //   subscription_id: "",
    //   subscription_plan_id: 54816,
    //   user_id: "",
    //   date_end: new Date(2023, 12, 15).toISOString(),
    //   date_created: new Date().toISOString(),
    // };
    return response.data.data as UserData;
  } catch (err: AxiosError | any) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const postUser = async (
  user: UserData,
  axiosInstance: AxiosInstance = axios
) => {
  try {
    const response = await axiosInstance.post<UserData>(apiUrl, user);
    return response.data;
  } catch (err: AxiosError | any) {
    throw err;
  }
};
