import React from 'react';
import { Grid, Col, Title, Text } from '@mantine/core';
import CheckboxAnswers from './CheckboxQuestion';
import RadioAnswers from './RadioQuestion';
import AutocompleteAnswers from './AutocompleteQuestion';
import { QuestionData } from '../../../types/surveyTypes';
import GridAutocompleteAnswers from './GridAutocompleteQuestion';

interface QuestionProps {
  question: QuestionData;
  onChange: (id: string) => void;
}

const Question: React.FC<QuestionProps> = ({ question, onChange }) => {
  return (
    <Grid gutter="md">
      <Col span={12} mb="xl">
        {question.text.includes("Are you aware of any specific allergies you have?") 
        ? (<>
            <Title order={3}>Are you aware of any specific allergies you have?</Title>
            <Text italic size="sm" mt="2px" mb="md" c='gray.6'>Please enter as many as you know</Text>
          </>)
        : (<Title order={3} mb="lg">{question.text}</Title>)}

        {question.is_multiselect ? (
          question.answers.length > 15 ? (          
            question.text.includes("Are you aware of any specific allergies you have?") ? 
              (<GridAutocompleteAnswers
                answers={question.answers}
                onChange={onChange}
              />)
              :
              (<AutocompleteAnswers
                answers={question.answers}
                onChange={onChange}
              />)
          ) : (
            <CheckboxAnswers
              answers={question.answers}
              onChange={onChange}
            />
          )
        ) : (
          <RadioAnswers
            name={question.text}
            answers={question.answers}
            onChange={onChange}
          />
        )}
      </Col>
    </Grid>
  );
};

export default Question;
