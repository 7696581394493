import { BaseContext, BaseContextAction } from "../../types/contextTypes";
import { SurveyPartData } from "../../types/surveyTypes";

// Interfaces
export interface SurveyContextState extends BaseContext<SurveyPartData[]> {
  isCompleted: boolean;
  currentPartIndex: number;
  progressPercentage: number;
}

export interface SurveyContextActionData {
  partId: string;
  questionId?: string;
  answerId?: string;
}

export interface SurveyContextAction
  extends BaseContextAction<SurveyContextActionData> {}

export interface SurveyContextActions {
  load: () => Promise<void>;
  savePart: (partId: string) => Promise<void>;
  changeAnswer: (
    partId: string,
    questionId: string,
    answerId: string
  ) => Promise<void>;
}

// Initial state
export const initialState: SurveyContextState = {
  data: [],
  isError: false,
  isLoading: false,
  isCompleted: false,
  currentPartIndex: 0,
  progressPercentage: 0
};

export const initialActionsState: SurveyContextActions = {
  load: () => Promise.resolve(),
  savePart: () => Promise.resolve(),
  changeAnswer: () => Promise.resolve()
};

// Actions
export const LOAD = "LOAD";
export const SAVE_PART = "SAVE_PART";
export const CHANGE_ANSWER = "CHANGE_ANSWER";
