import {
  Popover,
  Group,
  Button,
  Text,
  useMantineTheme,
  ButtonProps,
} from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useState } from "react";

export interface PopoverButtonProps extends ButtonProps {
  children: React.ReactNode;
  isEnabled: boolean;
  disabledHoverText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const PopoverButton: React.FC<PopoverButtonProps> = ({
  isEnabled,
  disabledHoverText,
  onClick,
  ...others
}) => {
  const theme = useMantineTheme();
  const [showHover, setShowHover] = useState(false);

  return (
    <Popover withArrow opened={showHover && !isEnabled}>
      <Popover.Target>
        <Group
          onMouseEnter={() => setShowHover(true)}
          onMouseLeave={() => setShowHover(false)}
        >
          <Button onClick={onClick} disabled={!isEnabled} {...others} />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <Group>
          <IconAlertTriangle color={theme.colors.yellow[7]} />
          <Text>{disabledHoverText}</Text>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};
