import React, { useState } from "react";
import {
  Box,
  Col,
  Grid,
  Text,
  Group,
  useMantineTheme,
  rem,
  Popover,
  ActionIcon,
  NavLink,
} from "@mantine/core";
import {
  IconClock,
  IconMapPin,
  IconBrandWhatsapp,
  IconMail,
  IconDeviceMobileMessage,
  IconBellX,
  IconPencilMinus,
  IconTrash,
  IconBell,
  IconDotsVertical,
} from "@tabler/icons-react";

import { NotificationData } from "../../../types/notificationTypes";
import { useRegionsContext } from "../../../contexts/RegionsContext";

interface NotificationItemProps {
  notification: NotificationData;
  onEdit: () => void;
  onDelete: () => void;
  onToggleStatus: () => void;
}

const formatTime = (time: string) => {
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(parseInt(hours), parseInt(minutes));
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onEdit,
  onDelete,
  onToggleStatus,
}) => {
  const theme = useMantineTheme();
  const { timezones } = useRegionsContext();
  const [popoverOpened, setPopoverOpened] = useState(false);

  const handleDelete = (event: React.MouseEvent) => {
    setPopoverOpened(false);
    event.stopPropagation();
    onDelete();
  };

  const handleEdit = (event: React.MouseEvent) => {
    setPopoverOpened(false);
    event.stopPropagation();
    onEdit();
  };

  const handleToggleStatus = (event: React.MouseEvent) => {
    setPopoverOpened(false);
    event.stopPropagation();
    onToggleStatus();
  };

  const handlePopoverClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setPopoverOpened((current: boolean) => !current);
  };

  const handleClosePopover = () => {
    setPopoverOpened(false);
  };

  return (
    <Box
      sx={(theme) => ({
        // backgroundColor:
        //   theme.colorScheme === "dark"
        //     ? theme.colors.dark[6]
        //     : theme.colors.gray[0],

        backgroundColor: !notification.is_enabled
          ? theme.colorScheme === "dark"
            ? `rgba(${theme.colors.dark[6]}, 0.6)`
            : `rgba(${theme.colors.gray[0]}, 0.6)`
          : theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],

        padding: theme.spacing.md,
        borderRadius: theme.radius.lg,
        border: notification.is_enabled
          ? `${rem(1)} solid ${theme.colors.gray[3]}`
          : `${rem(1)} solid ${theme.colors.gray[5]}`,
        height: "140px",
        cursor: "pointer",
        pointerEvents: notification.is_enabled ? "auto" : "none",

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[1],
        },
      })}
      onClick={onEdit}
    >
      <Grid gutter="md" columns={20}>
        <Col span={17}>
          <Group>
            <IconMapPin color={theme.colors.gray[6]} />
            <Text>
              {notification.region &&
                `${notification.region?.city_name}${
                  notification.region?.state_id
                    ? ", " + notification.region?.state_id
                    : ""
                }`}
            </Text>
          </Group>
          <Group my="sm" w="max-content" maw={"350px"}>
            <IconClock color={theme.colors.gray[6]} />
            <Text>
              {formatTime(notification.zoned_time)}{" "}
              {notification.timezone !== notification.region?.timezone_name &&
                `(${
                  timezones
                    .find((x) => x.timezone_name === notification.timezone)
                    ?.timezone_display_name.split(" (")[0]
                })`}
            </Text>
          </Group>
          <Group my="sm">
            {notification.phone_number && (
              <>
                {notification.channel === "whatsapp" ? (
                  <IconBrandWhatsapp color={theme.colors.green[3]} />
                ) : (
                  <IconDeviceMobileMessage color={theme.colors.blue[3]} />
                )}
                <Text>{notification.phone_number}</Text>
              </>
            )}
            {notification.email_address && (
              <>
                <IconMail color={theme.colors.red[3]} />
                <Text>
                  {notification.email_address.length > 18
                    ? notification.email_address.substring(0, 18) + "..."
                    : notification.email_address}
                </Text>
              </>
            )}
          </Group>
        </Col>
        <Col span={3} offset={0}>
          <Popover
            width={160}
            onClose={handleClosePopover}
            opened={popoverOpened}
            radius="md"
          >
            <Popover.Target>
              <ActionIcon
                mt="-3px"
                variant="transparent"
                onClick={handlePopoverClick}
                style={{ pointerEvents: "auto" }}
              >
                <IconDotsVertical size="1rem" />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown style={{ pointerEvents: "auto" }} p={0}>
              <NavLink
                label={!notification.is_enabled ? "Enable" : "Disable"}
                onClick={handleToggleStatus}
                icon={
                  !notification.is_enabled ? (
                    <IconBell size="1rem" stroke={1.5} />
                  ) : (
                    <IconBellX size="1rem" stroke={1.5} />
                  )
                }
              />
              <NavLink
                label="Edit"
                onClick={handleEdit}
                icon={<IconPencilMinus size="1rem" stroke={1.5} />}
              />
              <NavLink
                label="Delete"
                onClick={handleDelete}
                icon={<IconTrash size="1rem" stroke={1.5} />}
              />
            </Popover.Dropdown>
          </Popover>
        </Col>
      </Grid>
    </Box>
  );
};

export default NotificationItem;
