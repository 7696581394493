import React from 'react';
import { Radio, Grid, Col } from '@mantine/core';
import { AnswerData } from '../../../types/surveyTypes';

interface RadioAnswersProps {
  name: string;
  answers: AnswerData[];
  onChange: (id: string) => void;
}

const RadioAnswers: React.FC<RadioAnswersProps> = ({
  name,
  answers,
  onChange,
}) => (
  <Grid gutter="md">
    {answers.map((answer) => (
      <Col key={answer.id}>
        <Radio
          label={answer.text}
          checked={answer.is_user_answer}
          onChange={() => onChange(answer.id)}
        />
      </Col>
    ))}
  </Grid>
);

export default RadioAnswers;
