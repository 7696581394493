// NotificationContext.tsx
import { FC, ReactNode, createContext, useContext, useEffect } from "react";

import { initialActionsState, initialState } from "./types";
import useAsyncReducer from "../../hooks/useAsyncReducer";
import { surveyReducer } from "./reducer";
import { createSurveyActions } from "./createActions";

// Context
const SurveyContext = createContext(initialState);
const SurveyActionsContext = createContext(initialActionsState);

// Provider
const SurveyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(
    surveyReducer,
    initialState
  );
  const actions = createSurveyActions(dispatch);

  useEffect(() => {
    actions.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SurveyContext.Provider value={state}>
      <SurveyActionsContext.Provider value={actions}>
        {children}
      </SurveyActionsContext.Provider>
    </SurveyContext.Provider>
  );
};

// Hooks
const useSurveyContext = () => useContext(SurveyContext);
const useSurveyActionsContext = () => useContext(SurveyActionsContext);

// Export
export { SurveyProvider, useSurveyContext, useSurveyActionsContext };
