import { BaseContext, BaseContextAction } from "../../types/contextTypes";
import { NotificationData } from "../../types/notificationTypes";

// Interfaces
export interface NotificationContextState extends BaseContext<NotificationData[]> {};

export interface NotificationContextAction extends BaseContextAction<NotificationData> {};

export interface NotificationContextActions {
  load: () => Promise<void>,
  create: (notification: NotificationData) => Promise<void>,
  update: (notification: NotificationData) => Promise<void>,
  delete: (notification: NotificationData) => Promise<void>
}

// Initial state
export const initialState: NotificationContextState = {
  data: [],
  isError: false,
  isLoading: false
};

export const initialActionsState: NotificationContextActions = {
  load: () => Promise.resolve(),
  create: () => Promise.resolve(),
  update: () => Promise.resolve(),
  delete: () => Promise.resolve(),
}

// Actions
export const LOAD = "LOAD";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
