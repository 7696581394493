import authConfigJson from "./auth_config.json";
import apiConfigJson from "./api_config.json";
import prodAuthConfigJson from "./auth_config.prod.json";
import legalAgreementsConfigJson from "./legal_agreements_config.json";

export interface AuthConfig {
  domain: string;
  clientId: string;
  responseType: string;
  scope: string;
  audience?: string;
}

export interface ApiConfig {
  baseUrl: string;
}

export interface LegalAgreementsConfig {
  termsAndConditions: string;
  privacyPolicy: string;
}

const authConfig: AuthConfig =
  process.env.NODE_ENV === "production"
    ? {
        ...prodAuthConfigJson,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
        domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
      }
    : authConfigJson;

const apiConfig: ApiConfig =
  process.env.NODE_ENV === "production"
    ? {
        baseUrl: process.env.REACT_APP_API_BASE_URL || "",
      }
    : apiConfigJson;

const legalAgreementsConfig: LegalAgreementsConfig = legalAgreementsConfigJson;

export function getAuthConfig(): AuthConfig {
  return {
    ...authConfig,
  };
}

export function getApiConfig(): ApiConfig {
  return { ...apiConfig };
}

export function getLegalAgreementsConfig(): LegalAgreementsConfig {
  return { ...legalAgreementsConfig };
}
