// Pricing page consists of

import {
  MediaQuery,
  Card,
  Center,
  Title,
  Text,
  Stack,
  Group,
  Switch,
  useMantineTheme,
  Button,
  Badge,
  List,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { notifications as mantineNotifications } from "@mantine/notifications";

import {
  getSubscriptionPlans,
  SubscriptionPlanData,
} from "../../api/subscriptionApi";
import { useApiClient } from "../../contexts/AxiosContext";
import {
  useUserActionsContext,
  useUserContext,
} from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useNotificationActionsContext } from "../../contexts/NotificationsContext";

const yearDefaultPlan: SubscriptionPlanData = {
  id: 0,
  name: "",
  billing_type: "year",
  trial_days: 14,
  recurring_price: { USD: 60 },
};

const monthDefaultPlan: SubscriptionPlanData = {
  id: 0,
  name: "",
  billing_type: "month",
  trial_days: 14,
  recurring_price: { USD: 7 },
};

export default function PricingPage() {
  const Paddle = (window as any).Paddle;
  const theme = useMantineTheme();
  const userData = useUserContext();
  const userActions = useUserActionsContext();
  const notificationsActions = useNotificationActionsContext();
  const [isMonthly, setIsMonthly] = useState(false);
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [yearPlan, setYearPlan] =
    useState<SubscriptionPlanData>(yearDefaultPlan);
  const [monthPlan, setMonthPlan] =
    useState<SubscriptionPlanData>(monthDefaultPlan);

  useEffect(() => {
    getSubscriptionPlans(apiClient).then((plans) => {
      setYearPlan(
        plans?.find((plan) => plan.billing_type === "year") || yearDefaultPlan
      );
      setMonthPlan(
        plans?.find((plan) => plan.billing_type === "month") || monthDefaultPlan
      );
    });
  }, []);

  const onStartSubscriptionClicked = () => {
    if (userData.data?.subscription) {
      mantineNotifications.show({
        color: "green",
        title: "You already have an active subscription!",
        message: "",
        radius: "md",
      });
      return;
    }

    const planId = isMonthly ? monthPlan.id : yearPlan.id;

    Paddle.Checkout.open({
      product: planId,
      email: userData.data?.email,
      marketingConsent: true,
      allowQuantity: false,
      disableLogout: true,
      displayModeTheme: "light",
      passthrough: { user_id: userData.data?.id },
      successCallback: (data: any, err: any) => {
        console.log(data);
        mantineNotifications.show({
          color: "green",
          title: "Your subscription activated successfully!",
          message: "",
          radius: "md",
        });
        setTimeout(
          () =>
            Promise.all([notificationsActions.load(), userActions.load()]).then(
              () => navigate("/")
            ),
          5000
        );
        navigate("/purchase-success");
      },
    });
  };

  return (
    <MediaQuery smallerThan="sm" styles={{ margin: "0" }}>
      <Card mx="100px" my="lg" mih="93%">
        <Center>
          <Stack>
            <Title align="center" order={2}>
              Forget about exhausting allergy management
            </Title>
            <Text align="center" c="gray.6">
              Pick up the subscription plan that suits you best and{" "}
              <b>start your allergy-aware life today</b>!
            </Text>

            <Group position="center" mt="lg">
              <Text weight="bold" size="lg">
                Annual
              </Text>
              <Switch
                size="lg"
                onChange={(event) => setIsMonthly(event.currentTarget.checked)}
              />
              <Text size="lg">Monthly</Text>
            </Group>

            <Center mt="md">
              <Card withBorder style={{ overflow: "visible" }}>
                {!isMonthly && (
                  <Card.Section>
                    <Badge pos="relative" top="-15px" right="-24%">
                      <b>Save 30%</b> with annual billing!
                    </Badge>
                  </Card.Section>
                )}

                <Stack>
                  <Group
                    spacing="xs"
                    position="center"
                    mt={isMonthly ? "8px" : undefined}
                  >
                    {isMonthly ? (
                      <Text size="lg" weight="bold">
                        ${monthPlan.recurring_price.USD}
                      </Text>
                    ) : (
                      <Group spacing="4px" align="baseline">
                        <Text size="sm" td="line-through" c="gray.6">
                          ${monthPlan.recurring_price.USD}
                        </Text>
                        <Text size="lg" weight="bold">
                          ${(yearPlan.recurring_price.USD / 12).toFixed(2)}
                        </Text>
                      </Group>
                    )}
                    <Text size="sm" c="gray.6">
                      {isMonthly ? "per month" : "per month"}
                    </Text>
                  </Group>

                  <List c="gray.6" size="sm" spacing="sm" ml="xs" mt="xs">
                    <List.Item>
                      <b>
                        {isMonthly ? monthPlan.trial_days : yearPlan.trial_days}
                        -day free trial!
                      </b>
                    </List.Item>
                    <List.Item>
                      <Text size="sm" c="gray.6">
                        {isMonthly ? "Billed monthly" : "Billed annually"}
                      </Text>
                    </List.Item>
                    <List.Item>Up to 3 notifications per day</List.Item>
                  </List>

                  <Button
                    onClick={onStartSubscriptionClicked}
                    radius="xl"
                    mt="lg"
                  >
                    Start my free trial!
                  </Button>
                </Stack>
              </Card>
            </Center>

            <Center mt="sm">
              <IconInfoCircle
                fontStyle="italic"
                size="17px"
                color={theme.colors.gray[6]}
              />
              <Text ml="5px" align="center" inline size="sm" c="gray.6" italic>
                You can cancel your subscription at any time
              </Text>
            </Center>
          </Stack>
        </Center>
      </Card>
    </MediaQuery>
  );
}
