import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  footer: {
    width: '100%',

    borderTop: `${rem(1)} solid transparent`,
    [theme.fn.smallerThan('xs')]: {
      //bigger then aside
      zIndex: 9996,
      position: "relative",
    },
  },

  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,

    [theme.fn.largerThan("xl")]: {
      paddingLeft:"0px",
      paddingRight:"0px",
    },

    [theme.fn.smallerThan('sm')]: {
      height: 60,
      flexDirection: 'column',
      justifyContent: 'center',
    },

    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    
  },

  textResize: {
    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.sm,
    },
  }

}));