import { Card, Center, LoadingOverlay, MediaQuery, rem } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import RegistrationForm from "./RegistrationForm";
import { UserData } from "../../types/userTypes";
import {
  useUserActionsContext,
  useUserContext,
} from "../../contexts/UserContext";

function RegistrationPage() {
  const navigate = useNavigate();
  const { isLoading } = useUserContext();
  const userActions = useUserActionsContext();
  const { user } = useAuth0();

  const initialFormValues: UserData = {
    email: user?.email || "",
    first_name: user?.given_name || "",
    last_name: user?.family_name || "",
    terms_accepted: false,
  };

  const handleFormSubmit = (values: UserData) => {
    console.log({ values });
    userActions.create(values).then(() => {
      navigate("/survey");
    });
  };

  return (
    <Center top="20%" h="100%">
      <MediaQuery smallerThan="sm" styles={{ width: "fit-content" }}>
        <Card p="lg" w={rem(500)}>
          <LoadingOverlay visible={isLoading} />
          <RegistrationForm
            initialValues={initialFormValues}
            onSubmit={handleFormSubmit}
          />
        </Card>
      </MediaQuery>
    </Center>
  );
}

export default RegistrationPage;
