import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Stepper,
  Group,
  Text,
  Progress,
  Center,
  useMantineTheme,
} from "@mantine/core";
import { Col, Grid } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { DynamicSourceType } from "party-js/lib/systems/sources";
import party from "party-js";

import Question from "./Question/Question";
import {
  useSurveyContext,
  useSurveyActionsContext,
} from "../../contexts/SurveyContext";
import { PopoverButton } from "../../components/PopoverButton";
import { useStyles } from "./Survey.styles";
import { IconInfoCircle } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

const Survey: React.FC = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isLargeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
  const navigate = useNavigate();
  const { data, isCompleted, currentPartIndex } = useSurveyContext();
  const actions = useSurveyActionsContext();
  const [activeStep, setActiveStep] = useState(
    isCompleted ? 0 : currentPartIndex
  );
  const [userChangedPart, setUserChangedPart] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    setProgressValue((activeStep / data.length) * 100);
  }, [activeStep, data]);

  useEffect(() => {
    if (!userChangedPart && !isCompleted) {
      setActiveStep(currentPartIndex);
    }
  }, [currentPartIndex, isCompleted, userChangedPart, setActiveStep]);

  const isCurrentStepCompleted = useCallback(() => {
    const currentPart = data[activeStep];
    return currentPart.questions.every((q) =>
      q.answers.some((a) => a.is_user_answer)
    );
  }, [data, activeStep]);

  const handleSubmit = () => {
    setProgressValue(100);
    party.confetti(containerRef.current as DynamicSourceType, {
      count: 100,
      spread: 3000,
    });

    setTimeout(() => {
      navigate("/");
    }, 1250);
  };

  const nextStep = () => {
    if (!isCurrentStepCompleted()) {
      return;
    }

    setUserChangedPart(true);

    actions.savePart(data[activeStep].id);

    const nextStep = activeStep + 1;
    if (nextStep === data.length) {
      handleSubmit();
    } else {
      setActiveStep(nextStep);
    }
  };

  const prevStep = () => {
    setUserChangedPart(true);

    actions.savePart(data[activeStep].id);

    setActiveStep((current) => (current > 0 ? current - 1 : current));
  };

  const onStepClick = (stepIndex: number) => {
    if (stepIndex === activeStep) {
      return;
    }

    actions.savePart(data[activeStep].id);
    setActiveStep(stepIndex);
  };

  const shouldAllowSelectStep = (step: number) =>
    currentPartIndex >= step &&
    step !== activeStep &&
    (step < activeStep || isCurrentStepCompleted());

  return (
    <Container ref={containerRef} w="100%">
      <Progress mt="md" value={progressValue} size="xs" radius={0} />
      <Stepper
        active={activeStep}
        onStepClick={onStepClick}
        w="100%"
        size="xs"
        classNames={classes}
      >
        {data.map((part, index) => (
          <Stepper.Step
            key={part.id}
            label={<Text size="sm">{part.text}</Text>}
            allowStepSelect={shouldAllowSelectStep(index)}
          >
            <Center mt={isLargeScreen ? '-md' : undefined} mb="sm">
              <IconInfoCircle
                fontStyle="italic"
                size="17px"
                color={theme.colors.gray[6]}
              />
              <Text
                ml="5px"
                align="center"
                inline
                size="sm"
                c={theme.colors.gray[6]}
                italic
              >
                We safely store your data and use it only to personalize your
                messages
              </Text>
            </Center>
            <Grid gutter="md">
              {part.questions.map((question) => (
                <Col key={question.id}>
                  <Question
                    question={question}
                    onChange={(answerId: string) =>
                      actions.changeAnswer(part.id, question.id, answerId)
                    }
                  />
                </Col>
              ))}
            </Grid>
          </Stepper.Step>
        ))}
      </Stepper>

      <Group position="center" mb="lg">
        {activeStep > 0 && (
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
        )}
        {activeStep < data.length && (
          <PopoverButton
            isEnabled={isCurrentStepCompleted()}
            onClick={nextStep}
            disabledHoverText="Please fill in all answers to continue"
          >
            {activeStep === data.length - 1 ? "Finish survey" : "Next step"}
          </PopoverButton>
        )}
      </Group>
    </Container>
  );
};

export default Survey;
