import React from 'react';
import { Checkbox, Col, Grid } from '@mantine/core';
import { AnswerData } from '../../../types/surveyTypes';

interface CheckboxAnswersProps {
  answers: AnswerData[];
  onChange: (id: string) => void;
}

const CheckboxAnswers: React.FC<CheckboxAnswersProps> = ({
  answers,
  onChange,
}) => (
  <Grid gutter="md">
    {answers.map((answer) => (
      <Col key={answer.id}>
        <Checkbox radius='xs'
          label={answer.text}
          checked={answer.is_user_answer}
          onChange={() => onChange(answer.id)}
        />
      </Col>
    ))}
  </Grid>
);

export default CheckboxAnswers;
