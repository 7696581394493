import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  cardCenter : {
    [theme.fn.smallerThan('sm')]: {
      flexDirection: "column",
    },  
  },


  cardSelected: {
    borderRadius: "10px",  
    
    border: `1px solid ${theme.colors[theme.primaryColor][8]} !important`,
    color: `${theme.colors[theme.primaryColor][8]} !important`,
    backgroundColor: theme.colors[theme.primaryColor][0],

    width: "125px",
    [theme.fn.smallerThan('sm')]: {
      width: "93px"
    },  
  },

  card: {
    borderRadius:"10px",
    width: "125px",
    [theme.fn.smallerThan('sm')]: {
      width: "93px"
    },  
  },

  textSelected: {
    color: `${theme.colors[theme.primaryColor][8]} !important`,

    [theme.fn.smallerThan('sm')]: {
      marginLeft: "0px",
      marginTop: "5px"
    },  
  },

  text: {
    [theme.fn.smallerThan('sm')]: {
      marginLeft: "0px",
      marginTop: "5px"
    },  
    
  },

  msgPaper: {
    backgroundColor: 'rgba(255,255,255,0.6)',
    fontSize: theme.fontSizes.xs,
    borderRadius:"10px"
  },

  listPadding : {
    marginLeft: theme.spacing.md,
  },

  alwaysUnderline: {
    textDecoration: 'underline',
    textUnderlineOffset: "4px",
    //textDecorationThickness: "1px"
    // textDecorationColor: theme.colors.indigo.at(0)
    //textDecorationColor: theme.primaryColor.at(0)
  },

  optionsAnchor: {
    marginLeft: '13px',
    color: theme.colors.gray[6],

    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.sm,
    }
  },

  inputFieldLabel: {
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.sm
    }
  },

  inputField: {
    width: '95%'
  },

  stepTextBox: {
    paddingTop: "5px",
    paddingBottom: "10px"
  },

  steps: {
    margin: 0,
  }
}));